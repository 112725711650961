import React from "react";
import Logo from "../../public/images/logo.svg";
import Hamburger from "../../public/images/hambergur.svg";
import Bell from "../../public/images/bell.svg";
import { Link } from "react-router-dom";

// import '../../public/js/script';
// import profileImage from "../../public/images/user-profile.png";
const Header = (props) => {
  let userName = "User Name";
  // if (props.auth.isLoggedIn && props.auth.userProfile) {
  //   if (props.auth.userProfile.userName) {
  //     userName = props.auth.userProfile.userName;
  //   } else if (
  //     props.auth.userProfile.userType &&
  //     props.auth.userProfile.userType.userType == "Admin"
  //   ) {
  //     userName = "Admin";
  //   }
  // }
  // let nameFirstLetter = userName.charAt(0);

  function openNav() {
    document.getElementById("mySidenav").style.width = "428px";
  }

  return (
    <>
      <div className="header-area">
        <div className="header-left">
          <button type="button" onClick={openNav} style={{ cursor: "pointer" }}>
            <img src={Hamburger} alt="" />
          </button>
        </div>
        <div className="header-logo">
          <Link to="#">
            <img src={Logo} />
          </Link>
        </div>
        <div className="header-right">
          <Link to="#">
            <img src={Bell} alt="" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Header;
