import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getProfilePhoto, getUserProfile,
    getUpdateProfile
} from "./sharedApi";

const initialState = {
    profile: null,
    profilePhoto: null,
    userProfile: null,
    status: "idle",
    status1: {
        userProfile: "idle"
    }
};

export const profilePhotoAsync = createAsyncThunk("profile/photo", async (data) => {
    const response = await getProfilePhoto(data);
    return response.data;
});

export const updateProfileAsync = createAsyncThunk("update/profile", async (data) => {
    const response = await getUpdateProfile(data);
    return response.data;
});
export const userProfileAsync = createAsyncThunk("user/profile", async (data) => {
    const response = await getUserProfile(data);
    console.log(data,"data")
    return response.data;
});

export const sharedSlice = createSlice({
    name: "profile",
    initialState,

    extraReducers: {
        [profilePhotoAsync.pending](state) {
            state.status = "loading";
        },
        [profilePhotoAsync.fulfilled](state, { payload }) {
            console.log(payload,"fulfilled photo" )
            state.status = "idle";
            state.profilePhoto = payload;
        },
        [profilePhotoAsync.rejected](state, { error }) {
            state.status = "idle";
            state.profilePhoto = [];
        },
        [updateProfileAsync.pending](state) {
            state.status = "loading";
        },
        [updateProfileAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.updateprofile = payload;
        },
        [updateProfileAsync.rejected](state, { error }) {
            state.status = "idle";
            state.updateprofile = [];
        },
        [userProfileAsync.pending](state) {
            state.userProfile = "loading";
        },
        [userProfileAsync.fulfilled](state, { payload }) {
            state.userProfile = "idle";
            console.log(payload ,"usrprofileslicedatafullfilled")
            state.userProfile = payload;
        },
        [userProfileAsync.rejected](state, { error }) {
            state.userProfile = "idle";
            state.userProfile = [];
        },
    },
});

export const selectProfileData = (state) => state.shared.profile;
export const selectProfilePhoto = (state) => state.editProfilePhoto.profilePhoto;
export const selectUserProfile = (state) => state.shared.userProfile;
export default sharedSlice.reducer;