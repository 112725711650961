import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import VerifyBlack from "../../../../public/images/verify-black.svg";
import CustomModal from "../../../../components/shared/CustomModal"
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { useSelector, useDispatch } from "react-redux";
import { getDeliveryDetailsAsync, selectDeliveryData } from "../../deliverySlice"
import { selectLoginAuth } from "../../../auth/authSlice";
import { VerifyDeliveryOtpApiAsync, changeDeliveryStatusApiAsync } from "../../../dashboard/dashbaordSlice";
import downArrow from "../../../../public/images/downArrow.svg"
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
const VerifyDelivery = (props) => {
  const history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch()
  const deliveryData = useSelector(selectDeliveryData)
  const [otp, setOtp] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false
  });
  const locationData = JSON.parse(localStorage.getItem("requesteddata"))
  const [modalKey, setModalKey] = useState(Math.random());
  const auth = useSelector(selectLoginAuth)
  const [middlebody, setmiddlebody] = useState(false);
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries: ['places'],
  })
  const center = { lat: 30.6574932, lng: 76.727102 }

  async function calculateRoute() {
    if (locationData?.order_pick_address === '' || locationData?.order_delivery_address === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: locationData?.order_pick_address,
      destination: locationData?.order_delivery_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    console.log(results, "result value")
    setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setmiddlebody(current => !current);
  };
  const handleChange = (otp) => {
    setOtp(otp);
  }

  let data = {
    "postData": {
      "order_id": locationData?.order_id,
      "otp": "1234"
    },
    "tokenData": auth?.payload?.token
  }
  const handleVerify = async () => {
    if (!otp || otp.length < 5) {
      toast.error("Please enter your Otp");
      return false
    }
    await dispatch(VerifyDeliveryOtpApiAsync(data))
    let invalidData = await localStorage.getItem("invalidata")
    if (invalidData) {
      history.push('/dashboard/express-delivery/delivery/verify-delivery')
    } else {
      let data = {
        "postData": {
          "order_id": locationData?.order_id,
          "status": "Delivered"
        },
        "tokenData": auth?.payload?.token
      }
      dispatch(changeDeliveryStatusApiAsync(data))
      setModalDetail({ show: true });
      setModalKey(Math.random());
    }
  }

  const handleOnCloseModal = () => {
    setModalDetail({ show: false });
    setModalKey(Math.random());
  }

  useEffect(() => {
    dispatch(getDeliveryDetailsAsync())
    calculateRoute()
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <div className="MapOrders_inner">
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={map => setMap(map)}
            >
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </div>
          {" "}
        </div>
        
        {!modalDetail.show &&
          <div className="pickup-pointbox">
            <div className="deliver-verifybox" onClick={() => handleClick(true)}>
              <h2>Verify Delivery</h2>
              <p>
                Ask for Order code from shop and <br /> verify it
              </p>
              <img className="dropArrow" src={downArrow} onClick="" />
            </div>
            <div className={middlebody ? 'statusBody addClass' : "statusBody"}>
              <div className="verify-box d-flex justify-content-center">
                <OTPInput className='input_digits_'
                  value={otp}
                  numInputs={5}
                  isInputNum={true}
                  data-cy="pin-field"
                  onChange={handleChange}
                // onChange ={() => {}} 
                // onComplete={(code) => onComplete(code)}
                />
              </div>

              <div className="pickup-mbtn">
                <Link to="#" onClick={handleVerify}>
                  Verify
                </Link>
              </div>
            </div>
          </div>}

      </div>

      <CustomModal
        key={modalKey}
        show={modalDetail.show}
        backdrop="static"
        title={modalDetail.title}
        showCloseBtn={true}
        child={
          <div className="modal-body">
            <div className="modal-mainprt modal-boxblack">
              <div className="mdlverify-cnt">
                <h4>Verified</h4>
                <img src={VerifyBlack} alt="" />
                <p>You have completed delivery!</p>
                <h6>You received fee ${locationData?.amount}</h6>
              </div>
              <div className="popup-btn2">
                <ul>
                  <li>
                    <Link to="#" onClick={handleOnCloseModal}>
                      Cancel
                    </Link>
                  </li>
                  <li>
                    <Link to='/dashboard/wallet' className="active">
                      Wallet
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        }
        // size="lg"
        // footerContent = {}
        // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
        onCloseModal={() => handleOnCloseModal()}
      />

    </>
  );
};

export default VerifyDelivery;
