import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../public/images/logo.svg";
import LeftArrow from "../../public/images/left-arrow.svg";
import Clock from "../../public/images/clock.svg";
import Location from "../../public/images/location.svg";
import Timeline from "../../public/images/timeline-prof.png";
import "react-phone-number-input/style.css";
import { useSelector, useDispatch } from "react-redux";
import { getdeliveryRequestApiAsync, getdeliveryRequestDeliveredApiAsync, getdeliveryRequestReturnedApiAsync, getMyDeliveryAsync, selectDeliveryRequestData, selectDeliveryRequestDeliveredData, selectDeliveryRequestReturnedData, selectMyDeliveryData } from "./dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";

const MyDeliveries = (props) => {
  const history = useHistory();

  const [pendingFlag, setPendingFlag] = useState(true);
  const [deliveredFlag, setDeliveredFlag] = useState(false);
  const [returnedFlag, setReturnedFlag] = useState(false);
  const deliveryData = useSelector(selectMyDeliveryData)
  const pendingData = useSelector(selectDeliveryRequestData)
  const deliveredData = useSelector(selectDeliveryRequestDeliveredData)
  const returnedData = useSelector(selectDeliveryRequestReturnedData)

  console.log(pendingData, "pending data")
  // console.log(deliveredData,"delivered data")
  // console.log(returnedData,"returned data")
  const auth = useSelector(selectLoginAuth)
  const dispatch = useDispatch()

  const registerPage = (e) => {
    e.preventDefault();
    history.push("/register");
  };

  useEffect(() => {
    dispatch(getMyDeliveryAsync())
    dispatch(getdeliveryRequestReturnedApiAsync(auth?.payload?.token))
    dispatch(getdeliveryRequestDeliveredApiAsync(auth?.payload?.token))
    dispatch(getdeliveryRequestApiAsync(auth?.payload?.token))
    setPendingFlag(true);
    setDeliveredFlag(false);
    setReturnedFlag(false);
  }, []);

  const changeSelection = (e, flag) => {
    e.preventDefault();
    if (flag === "pending") {
      setPendingFlag(true);
      setDeliveredFlag(false);
      setReturnedFlag(false);
    } else if (flag === "delivered") {
      setPendingFlag(false);
      setDeliveredFlag(true);
      setReturnedFlag(false);
    } else if (flag === "returned") {
      setPendingFlag(false);
      setDeliveredFlag(false);
      setReturnedFlag(true);
    }
  };

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            {" "}
            <img src={LeftArrow} alt="" />{" "}
          </Link>{" "}
          My Deliveries
        </h4>
      </div>

      <div className="support-main">
        <div className="tabs">
          <div className="tab-upper delv-tabupr">
            <ul className="tab-links">
              <li className={pendingFlag ? "active" : ""}>
                <Link to="#" onClick={(e) => changeSelection(e, "pending")}>
                  Pending ({pendingData?.data?.payload ? pendingData?.data?.payload?.length : 0})
                </Link>
              </li>
              <li className={deliveredFlag ? "active" : ""}>
                <Link to="#" onClick={(e) => changeSelection(e, "delivered")}>
                  Delivered ({deliveredData?.data?.payload?.length ? deliveredData?.data?.payload?.length : 0})
                </Link>
              </li>
              <li className={returnedFlag ? "active" : ""}>
                <Link to="#" onClick={(e) => changeSelection(e, "returned")}>
                  Returned ({returnedData?.data?.payload?.length ? returnedData?.data?.payload?.length : 0})
                </Link>
              </li>
            </ul>
          </div>

          <div className="tab-content">
            {pendingFlag && (
              <>
                <div id="tab1" className="tab active">
                  {pendingData?.data?.payload?.length > 0 ? (
                    <>
                      {pendingData?.data?.payload?.map(
                        (pendingValue, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div>
                                <div
                                  className="support-rewtab1 pt-4"
                                  id={index}
                                >
                                  <h2>
                                    #{pendingValue.driver_id} <span>Pending</span>
                                  </h2>
                                  <div className="tab1-pendinr">
                                    <span>
                                      <img src={Clock} alt="" />{" "}
                                      11 min
                                    </span>
                                    <span>
                                      <img src={Location} alt="" />{" "}
                                      12 km
                                    </span>
                                  </div>

                                  <div className="verticle-timeline">
                                    <ul>
                                      <li className="check">
                                        <h4>Pickup point</h4>
                                        <p>{pendingValue?.order_pick_address}</p>
                                      </li>
                                      <li>
                                        <h4 className="dark-thd">
                                          Delivery point
                                        </h4>
                                        <div className="timeline-prof">
                                          <img src={Timeline} alt="" />
                                          <div className="timeline-profcnt">
                                            <h2>
                                              abc
                                            </h2>
                                            <p>
                                              {pendingValue?.order_delivery_address}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="horizental-timeline">
                                    <ul>
                                      <li className="active">Picked</li>
                                      <li>Delivered</li>
                                      <li className="verify-dark">Verified</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              </>
            )}

            {deliveredFlag && (
              <>
                <div id="tab2" className="tab active">
                  {deliveredData?.data?.payload?.data.length > 0 ? (
                    <>
                      {deliveredData?.data?.payload?.data?.map(
                        (pendingValue, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div>
                                <div
                                  className="support-rewtab1 pt-4"
                                  id={index}
                                >
                                  <h2>
                                    #{pendingValue.driver_id} <span>Pending</span>
                                  </h2>
                                  <div className="tab1-pendinr">
                                    <span>
                                      <img src={Clock} alt="" />{" "}
                                      11 min
                                    </span>
                                    <span>
                                      <img src={Location} alt="" />{" "}
                                      12 km
                                    </span>
                                  </div>

                                  <div className="verticle-timeline">
                                    <ul>
                                      <li className="check">
                                        <h4>Pickup point</h4>
                                        <p>{pendingValue?.order_pick_address}</p>
                                      </li>
                                      <li>
                                        <h4 className="dark-thd">
                                          Delivery point
                                        </h4>
                                        <div className="timeline-prof">
                                          <img src={Timeline} alt="" />
                                          <div className="timeline-profcnt">
                                            <h2>
                                              abc
                                            </h2>
                                            <p>
                                              {pendingValue?.order_delivery_address}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="horizental-timeline">
                                    <ul>
                                      <li className="active">Picked</li>
                                      <li>Delivered</li>
                                      <li className="verify-dark">Verified</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              </>
            )}

            {returnedFlag && (
              <>
                <div id="tab3" className="tab active">
                  {returnedData?.data?.payload?.data?.length > 0 ? (
                    <>
                      {deliveredData?.data?.payload?.data?.map(
                        (pendingValue, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div>
                                <div
                                  className="support-rewtab1 pt-4"
                                  id={index}
                                >
                                  <h2>
                                    #{pendingValue.driver_id} <span>Pending</span>
                                  </h2>
                                  <div className="tab1-pendinr">
                                    <span>
                                      <img src={Clock} alt="" />{" "}
                                      11 min
                                    </span>
                                    <span>
                                      <img src={Location} alt="" />{" "}
                                      12 km
                                    </span>
                                  </div>

                                  <div className="verticle-timeline">
                                    <ul>
                                      <li className="check">
                                        <h4>Pickup point</h4>
                                        <p>{pendingValue?.order_pick_address}</p>
                                      </li>
                                      <li>
                                        <h4 className="dark-thd">
                                          Delivery point
                                        </h4>
                                        <div className="timeline-prof">
                                          <img src={Timeline} alt="" />
                                          <div className="timeline-profcnt">
                                            <h2>
                                              abc
                                            </h2>
                                            <p>
                                              {pendingValue?.order_delivery_address}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>

                                  <div className="horizental-timeline">
                                    <ul>
                                      <li className="active">Picked</li>
                                      <li>Delivered</li>
                                      <li className="verify-dark">Verified</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>No Data Found</>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyDeliveries;