import React from 'react'
import Visa from "../../public/images/visa.png";
import BankFilledPng from "../../public/images/bank-filled.png";
import { Link } from 'react-router-dom';

const NormalTransfer = (props) => {
    return (
        <div className="modal-content wallet-modalcontent">
            <div className="modal-header">
                <button
                    type="button"
                    className="btn-close"
                    onClick={props?.close}
                >
                    <span aria-hidden="true">close</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="modal-mainprt wallet-modal">
                    <div className="wallet-mdlhead">
                        <h2>Transfer Balance</h2>
                    </div>

                    <div className="wallet-mdlupper">
                        <h2>
                            <sup>$</sup>0
                        </h2>
                        <p>Your balance : JBR {props?.walletData?.balance}</p>
                        <Link
                            to="#"
                            className="normal-trnmdl"
                            onClick={() => { props.details('instant_transfer') }}
                        >
                            <span>
                                <i className="fas fa-chevron-left"></i>
                            </span>
                            <img src={BankFilledPng} alt="" />
                            <span className="instant-transcnt">
                                <h4>Normal </h4>
                                <p>Free transer (1-3 business days)</p>
                            </span>
                        </Link>
                    </div>

                    <div className="transfer-mdlitem">
                        <ul>
                            <li>
                                Fee <span>$0.0</span>
                            </li>
                            <li>
                                <Link to="#">
                                    <strong>Transfer to</strong>{" "}
                                    <span>
                                        <img src={Visa} alt="" /> Visa ..2659{" "}
                                        <i className="fas fa-chevron-right"></i>
                                    </span>
                                </Link>
                            </li>
                        </ul>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s, when an unknown
                            printer took a galley of type
                        </p>
                    </div>

                    <div className="trans-mdlbtn">
                        <Link to="#">Transfer $0.00</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NormalTransfer