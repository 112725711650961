import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Location from "../../../../public/images/track-location-2.png";
import Shop from "../../../../public/images/shop.png";
import LogoIcon from "../../../../public/images/logo-icon_2.svg";
import { useSelector, useDispatch } from "react-redux";
import { getReturnDetailsAsync, selectReturnData } from "../../deliverySlice"
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'

const WayToReturn2 = (props) => {
  const history = useHistory();

  const dispatch = useDispatch()
  const returnData = useSelector(selectReturnData)
  const locationData = JSON.parse(localStorage.getItem("requesteddata"))
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries: ['places'],
  })
  const center = { lat: 30.6574932, lng: 76.727102 }

  async function calculateRoute() {
    if (locationData?.order_pick_address === '' || locationData?.order_delivery_address === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: locationData?.order_pick_address,
      destination: locationData?.order_delivery_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    console.log(results, "result value")
    setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }

  useEffect(() => {
    dispatch(getReturnDetailsAsync())
    calculateRoute()
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <div className="MapOrders_inner">
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={map => setMap(map)}
            >
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </div>
          {" "}
        </div>
        <div className="reqaccpt-exprs text-center">
          <span>
            Return request:{" "}
            <strong>{returnData?.numberOfReturnRequests}</strong>
          </span>
        </div>

        <div className="track-lcitem">
          <img src={Location} alt="" />
        </div>

        <div className="pickup-pointbox">
          <div className="accept-mdlupper">
            <h2>
              Way to Return<span>{returnData?.id}</span>
            </h2>
          </div>

          <div className="accptmdl-uprtimeline">
            <ul>
              <li>
                <div className="tmmin-cnt">
                  <p>
                    {returnData?.eta} mins{" "}
                    <span>{returnData?.estimatedDistance} km</span>
                  </p>
                </div>
                <div className="logot-icon2">
                  <img src={LogoIcon} alt="" />
                </div>
              </li>
              <li>
                <div className="shop-tml">
                  <img src={Shop} alt="" />
                </div>
              </li>
            </ul>
          </div>

          <div className="accept-mdltimline">
            <div className="verticle-timeline">
              <ul>
                <li>
                  <h4>Return point</h4>
                  <p>{returnData?.returnPointAddress}</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="pickup-mbtn">
            <Link to="#"> Mark as Return</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default WayToReturn2;

