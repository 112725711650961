import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import DeliveryMan from "../../../public/images/delivery-man.png";
import Verify from "../../../public/images/verify.svg";
import Logo from "../../../public/images/logo.svg";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { cityAsync, countryAsync, districtAsync, stateAsync, selectCity, selectCountry, selectDistrict, selectState } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const AreaCodeWithState = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  let country = useSelector(selectCountry)
  let district = useSelector(selectDistrict)
  let city = useSelector(selectCity)
  let state = useSelector(selectState)
  // console.log(country,"country");
  // console.log(district,"district");
  // console.log(city,"city");
  const [countryValue, setCountryValue] = useState(-1);
  const [districtValue, setDistrictValue] = useState(-1)
  const [stateValue, setStateValue] = useState(-1)
  const [cityValue, setCityValue] = useState(-1);

  let countryVal = country?.payload?.data.filter(id => id?.id == countryValue);
  let districtVal = district?.payload?.data.filter(id => id?.id == districtValue);
  let stateVal = state?.payload?.data.filter(id => id?.id == stateValue);
  let cityVal = city?.payload?.data.filter(id => id?.id == cityValue);

  console.log(countryValue,districtValue,stateValue,cityValue)

  const handleChange = async (e, flag) => {
    e.preventDefault();
    if (flag == "country") {
      dispatch(districtAsync(e.target.value))
        .then(unwrapResult)
        .then((obj) => {
          if (!toast.isActive(toastId.current)) {
          }
          setCountryValue(e.target.value)
          setDistrictValue("");
          setCityValue("");
        }
        )
        .catch((obj) => {
        })
    }
    if (flag == "district") {
      dispatch(stateAsync(e.target.value))
        .then(unwrapResult)
        .then((obj) => {
          if (!toast.isActive(toastId.current)) {
          }
          setDistrictValue(e.target.value)
          setCityValue("");
        }
        )
        .catch((obj) => {
        })
    }
    if (flag == "state") {
      dispatch(cityAsync(e.target.value))
        .then(unwrapResult)
        .then((obj) => {
          setStateValue(e.target.value)
          setCityValue("");
        }
        )
        .catch((obj) => {
        })
    }
    if (flag == "city") {
      setCityValue(e.target.value)
    }
  }

  let data = {}
  if (countryVal && districtVal && cityVal && stateVal) {
    data = {
      country: countryVal[0]?.name,
      district: districtVal[0]?.name,
      state: stateVal[0]?.name,
      city: cityVal[0]?.name
    }
  }
  const handleSubmit = async () => {
    if (!(countryVal.length > 0)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select country")
      }
      return
    }
    if (!(districtVal.length > 0)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select District")
      }
      return
    }
    if (!(stateVal.length > 0)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select state")
      }
      return
    }
    if (!(cityVal.length > 0)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select City")
      }
      return
    }

    if ((countryVal.length > 0) && (districtVal.length > 0) && (stateVal.length > 0) && (cityVal.length > 0 )) {
      history.push({ pathname: "/create-profile", state: JSON.stringify(data) });
    }
    localStorage.setItem("location", JSON.stringify(data))
  };

  useEffect(() => {
    dispatch(countryAsync())
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="logo-part">
          <Link to="#">
            <img src={Logo} alt="" />
          </Link>
        </div>

        <div className="delivery-driver">
          <img src={DeliveryMan} alt="" />
        </div>

        <div className="driver-form">
          <form action="#" method="POST">
            <h2>
              Welcome to <span>JOBR Driver app</span>
            </h2>
            <select className="country-name" defaultValue={"Select Country"} name="country" id="countrylist" onChange={(e) => handleChange(e, "country")}>
              <option value="Select Country" disabled>Select Country</option>
              {country?.payload?.data?.map((value, index) => {
                return (
                  <option value={value?.id} key={index}>{value?.name}</option>
                )
              })}
            </select>
            <select name="district" defaultValue={"Select District"} id="districtlist" onChange={(e) => handleChange(e, "district")} disabled={countryValue == -1}>
              <option value="Select District" disabled>Select District</option>
              {district?.payload?.data?.map((value, index) => {
                return (
                  <option value={value?.id} key={index}>{value?.name}</option>
                )
              })}
            </select>
            <select name="state" defaultValue={"Select State"} id="statelist" onChange={(e) => handleChange(e, "state")} disabled={countryValue == -1}>
              <option value="Select State" disabled>Select State</option>
              {state?.payload?.data?.map((value, index) => {
                return (
                  <option value={value?.id} key={index}>{value?.name}</option>
                )
              })}
            </select>
            <select name="city" defaultValue={"Select City"} id="citylist" onChange={(e) => handleChange(e, "city")} disabled={stateValue == -1}>
              <option value="Select City" disabled>Select City</option>
              {city?.payload?.data?.map((value, index) => {
                return (
                  <option value={value?.id} key={index}>{value?.name}</option>
                )
              })}
            </select>
            <Link to="#" onClick={handleSubmit} style={{ width: "100%" }}>
              <button type="submit">Next</button>
            </Link>
          </form>
        </div>
      </main>
    </>
  );
};

export default AreaCodeWithState;