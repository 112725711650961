import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../public/images/left-arrow.svg";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import Verify from "../../public/images/verify.svg";
import CustomModal from "../../components/shared/CustomModal"
import { changePinAsync, loginAsync, selectPhoneVerify, selectOtpId } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const ReEnterNewPin = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const location = useLocation()
  const [otp, setOtp] = useState("");
  const [modalDetail, setModalDetail] = useState({
    show: false
  });
  const [modalKey, setModalKey] = useState(Math.random());
  let phonedetails = JSON.parse(localStorage.getItem("phonedetails"))

  const phoneVerify = useSelector(selectPhoneVerify)
  const handleChange = (otp) => {
    setOtp(otp);
  }
  let data = {
    "phone_code": phonedetails?.phone_code,
    "phone_number": phonedetails?.phone_no,
    "pin": otp,
    "otp": `${phoneVerify?.payload?.otp}`
  }
  // console.log(otp, "reenter data")

  const handleNext = async () => {
    const setPin = await localStorage.getItem("setpin")
    if (otp === setPin) {
      dispatch(changePinAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg)
          }
          setTimeout(() => {
            setModalDetail({ show: true });
            setModalKey(Math.random());
          }, 1000);
          localStorage.removeItem("setpin")
        }
        )
        .catch((obj) => {
          // if (!toast.isActive(toastId.current)) {
          //   toastId.current = toast.error(obj?.message)
          // }
        })
    }
    else {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Otp not matched")
      }
    }
  }

  const loginPage = (e) => {
    e.preventDefault();
    let data = {
      "phone_code": phonedetails?.phone_code,
      "phone_number": phonedetails?.phone_no,
      "password": otp,
      "role_slug": "driver"
    }
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.success("New Pin Created Successfully")
    }
    dispatch(loginAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        if (obj?.payload?.user_profiles?.driver_steps < 5) {
          return history.push('/register')
        }
        if (obj?.payload?.user_profiles?.driver_steps === 5) {
          return history.push('/dashboard')
        }
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
  };

  const handleOnCloseModal = () => {
    setModalDetail({ show: false });
    setModalKey(Math.random());
  }

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      {!modalDetail.show &&
        <div className="pin-upper" style={{ position: "relative" }}>
          <Link to={"/set-new-pin"}>
            <img src={LeftArrow} alt="" />
          </Link>
          <span
            style={{ position: "absolute", left: "45px", fontWeight: "600" }}
          >
            Set Up PIN
          </span>
          <Link to={"/set-new-pin"}>
            <div className="cancel-btn">Cancel</div>
          </Link>
        </div>
      }

      <div className="pin-main">
        <p>Re-Enter your PIN</p>
        <div className="verify-box d-flex justify-content-center ">
          <OTPInput className='input_digits_'
            value={otp}
            numInputs={4}
            isInputNum={true}
            data-cy="pin-field"
            onChange={handleChange}
          // onComplete={(code) => onComplete(code)}
          />
        </div>
      </div>
      {
        !modalDetail.show &&
        <div className="bottom-part">
          <Link to="#" className="bottom-btn" onClick={handleNext}>Next</Link>
        </div>
      }

      <CustomModal
        key={modalKey}
        show={modalDetail.show}
        backdrop="static"
        title={modalDetail.title}
        wallentModal={true}
        child={
          <div className="modal-mainprt">
            <div className="mdlverify-cnt">
              <h4>Done!</h4>
              <img src={Verify} alt="" />
              <p>You are verified!</p>
              <h6>Your new pin is set now</h6>
            </div>
            <div className="mdlverify-btn mdllogin-btn">
              <Link
                to="#"
                className="active"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  loginPage(e);
                }}
              >
                Log in
              </Link>
            </div>
          </div>
        }
        // size="lg"
        // footerContent = {}
        // footerClasses = 'justify-content-start' // justify-content-start, justify-content-end, justify-content-center
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default ReEnterNewPin;