import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Profile from "../../public/images/profile.png";
import ParcelWhite from "../../public/images/parcel-white.svg";
import WalletWhite from "../../public/images/wallet-white.svg";
import UserWhite from "../../public/images/user-white.svg";
import RewardWhite from "../../public/images/reward-white.svg";
import TrackWhite from "../../public/images/track-white.svg";
import SettingsWhite from "../../public/images/settings-white.svg";
import SupportWhite from "../../public/images/support-white.svg";
import Power from "../../public/images/power.svg";
import { useSelector, useDispatch } from "react-redux";
import { getProfileAsync, selectUserProfile,selectProfileData, userProfileAsync } from "./sharedSlice"
import { logout,selectLoginProfile } from "../../containers/auth/authSlice";

const Sidebar = (props) => {
  const history = useHistory();
  const [status, setStatus] = useState("online")
  // const [profile, setProfile] = useState();
  const dispatch = useDispatch(userProfileAsync)
  const profile = useSelector(selectProfileData);
  const selectProfile = useSelector(selectUserProfile)
  const selectLoginUser = useSelector(selectLoginProfile);
    let loginId = selectLoginUser?.payload?.id;
  // console.log(selectProfile,"profileeeeeeee side bar")
  const handleLogout = async () => {
    await dispatch(logout())
    window.localStorage.clear()
    await history.push('/')
  }
  
  useEffect(() => {
    let params = {
      loginId: loginId,
      token: selectLoginUser.payload.token
  }
    dispatch(userProfileAsync(params))
  }, [])

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }
  
  return (
    <>
      <div className="side-manubar">
        <div id="mySidenav" className="sidenav">
          <Link to="#" className="closebtn" onClick={closeNav}>
            &times;
          </Link>
          <div className="side-upper">
          <img src={selectProfile?.payload?.user_profiles?.profile_photo ? selectProfile?.payload?.user_profiles?.profile_photo : Profile} alt="Profile" />
            <div className="side-uprcnt">
              <h4>{selectProfile?.payload?.user_profiles?.firstname} </h4>
              <p>
                Successful Delivery: <span>{selectProfile?.deliveries}</span>
              </p>
              <div className="status-onof">
                <h6>Online</h6>
                <label className="switch">
                  <input type="checkbox" value="online" checked={status == 'online'} onChange={(e) => setStatus(e.target.value)} />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="sidemenu-links">
            <span className="line"></span>
            <ul>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    closeNav();
                    history.push("/dashboard/my-deliveries");
                  }}
                >
                  <img src={ParcelWhite} alt="" /> My Deliveries
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    closeNav();
                    history.push("/dashboard/wallet");
                  }}
                >
                  <img src={WalletWhite} alt="" /> My Wallet
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    closeNav();
                    history.push("/dashboard/my-profile");
                  }}
                >
                  <img src={UserWhite} alt="" /> My Profile
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    closeNav();
                    history.push("/dashboard/reward");
                  }}
                >
                  <img src={RewardWhite} alt="" /> Reward
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    closeNav();
                    history.push("/dashboard/app-tracker");
                  }}
                >
                  <img src={TrackWhite} alt="" /> App tracker
                </Link>
              </li>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    closeNav();
                    history.push("/dashboard/settings");
                  }}
                >
                  <img src={SettingsWhite} alt="" /> Settings
                </Link>
              </li>
            </ul>
          </div>

          <div className="sidemenu-bottom mt-auto">
            <span className="line"></span>
            <ul>
              <li>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    closeNav();
                    history.push("/dashboard/support");
                  }}
                >
                  <img src={SupportWhite} alt="" /> Support
                </Link>
              </li>
              <li>
                <Link to="#" onClick={() => handleLogout()}>
                  <img src={Power} alt="" /> <span>Log out</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;