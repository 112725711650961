import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../../public/images/left-arrow.svg";
import "react-phone-number-input/style.css";
import { cityAsync, countryAsync, districtAsync, driverDocumentStepAsync, selectCity, selectCountry, selectDistrict, selectLoginAuth, selectRegisterAuth } from "../authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { getAddressFromCoordinates } from "./AddressMethod";
import { ToastBody } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

const RegistrationDocPi = (props) => {
  const history = useHistory();
  const [dob, setDob] = useState("")
  const [ssn, setSsn] = useState("")
  const [gender, setGender] = useState("male")
  const [streetAddress, setStreetAddress] = useState("")
  const [apt, setApt] = useState("")
  const [zipcode, setZipCode] = useState("")
  const [address, setAddress] = useState("")
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  let country = useSelector(selectCountry)
  let district = useSelector(selectDistrict)
  let city = useSelector(selectCity)
  const { status1 } = useSelector(state => state.auth)
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("")
  const [cityValue, setCityValue] = useState("")

  const handleChangeAddress = async (address) => {
    setAddress(address)
    setZipCode("")
    setCountryValue("")
    setCityValue("")
    setStateValue("")
    setApt("")
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng =>
        getAddressFromCoordinates(latLng.lat, latLng.lng)
          .then(data => {
            console.log(data, "data of address")
            for (var i = 0; i < data.address_components.length; i++) {
              if (data.address_components[i].types[0] == 'postal_code') {
                setZipCode(data?.address_components?.[i]?.long_name);
              }
              if (data.address_components[i].types[0] == 'country') {
                setCountryValue(data?.address_components?.[i]?.long_name);
              }
              if (data.address_components[i].types[0] == 'administrative_area_level_2' ||
                data.address_components[i].types[0] == 'administrative_area_level_3' ||
                data.address_components[i].types[0] == 'locality'
              ) {
                setCityValue(data?.address_components?.[i]?.long_name);
              }
              if (data.address_components[i].types[0] == 'administrative_area_level_1') {
                setStateValue(data?.address_components?.[i]?.long_name);
              }
              if (data.address_components[i].types[0] == 'street_number') {
                setApt(data.address_components?.[i]?.long_name);
              }
            }
          })
          .catch(err => console.log('error getting address', err))
      )
      .catch(error => console.error('Error', error));
  };
  const handleSelect = async value => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddress(value)
  };

  const registerAuth = useSelector(selectRegisterAuth);
  const auth = useSelector(selectLoginAuth)

  const gotoRegister2Page = (e) => {
    e.preventDefault();
    history.push("/register2");
  };
  let data = {}
  if (apt) {
    data = {
      "postData": {
        "driver_steps": "1",
        "wallet_steps": 0,
        "dob": dob,
        "ssn_number": ssn,
        "gender": gender,
        "current_address": {
          "street_address": address,
          "apt": apt,
          "state": stateValue,
          "city": cityValue,
          "postal_code": zipcode,
          "country": countryValue,
          "address_type": "current"
        }
      },
      "tokenData": auth?.payload?.token ? auth?.payload?.token : registerAuth?.payload?.token
    }
  }
  else {
    data = {
      "postData": {
        "driver_steps": "1",
        "wallet_steps": 0,
        "dob": dob,
        "ssn_number": ssn,
        "gender": gender,
        "current_address": {
          "street_address": address,
          "state": stateValue,
          "city": cityValue,
          "postal_code": zipcode,
          "country": countryValue,
          "address_type": "current"
        }
      },
      "tokenData": auth?.payload?.token ? auth?.payload?.token : registerAuth?.payload?.token
    }


  }
  const gotoRegistrationDocDdPage = async (e) => {
    e.preventDefault();
    if (!dob) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your DOB");
      }
      return;
    }
    if (!ssn) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your ssn");
      }
      return;
    }
    if (ssn.length < 9) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("ssn number should be of 9 digits");
      }
      return;
    }
    if (!address.length > 0) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your street address");
      }
      return;
    }
    if (!zipcode) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your zipcode");
      }
      return;
    }
    if (!countryValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select country")
      }
      return
    }
    if (!stateValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select District")
      }
      return
    }
    if (!cityValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Select City")
      }
      return
    }
    await dispatch(driverDocumentStepAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj, "docpi data")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push("/registration-doc-dd");
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })


  };
  // datePickerId.max = new Date().toISOString().split("T")[0];
  return (
    <>
      <main className="main-wrapper">
        <div className="registration-upper reg-heading2">
          <h4>
            <Link to="#" onClick={(e) => gotoRegister2Page(e)}>
              <img src={LeftArrow} alt="" />
            </Link>{" "}
            Documents collections
          </h4>
        </div>

        <div className="personal-infomain">
          <h2>Personal Information</h2>
          <label htmlFor="p1">Date of birthday (month / day / year)</label>
          {/* <input
              className="date"
              type="date"
              id="datePickerId"
              placeholder="mm / dd / yyyy"
              value={dob}
              // max={date}
              onChange={e => setDob(e.target.value)}
            /> */}
          <form>
            <DatePicker
              selected={dob}
              onChange={(date) => setDob(date)}
              dateFormat="MM/dd/yyyy"
              maxDate={moment().subtract(21,'years')._d}
              placeholderText="MM/DD/YYYY"
              className="date"
              autoComplete="off"
            />
          </form>
          <input type="password" placeholder="SSN" value={ssn} onChange={e => setSsn(e.target.value)} maxLength={9} />
          <div className="gender-prt">
            <h4>Gender</h4>
            <div className="">
              <label className='radioInline'>
                <input type="radio" name="age" value="30" checked={gender === 'male'}
                  onChange={() => setGender('male')} />Male
              </label>
              <label className='radioInline'>
                <input type="radio" name="age" value="30" checked={gender === 'female'}
                  onChange={() => setGender('female')} />Female
              </label>
            </div>
          </div>
          <label htmlFor="p2">Current Addreess</label>
          <PlacesAutocomplete
            value={address}
            onChange={handleChangeAddress}
            onSelect={handleSelect}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: 'Street Address',
                    className: 'location-search-input',
                  })}
                />
                <div className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          <input type="text" placeholder="Apt. " value={apt} onChange={e => setApt(e.target.value)} />
          <input type="text" placeholder="Please enter city " value={cityValue} onChange={e => setCityValue(e.target.value)} />
          <input type="text" placeholder="Please enter state " value={stateValue} onChange={e => setStateValue(e.target.value)} />
          <input type="text" placeholder="Zip Code" value={zipcode} onChange={e => setZipCode(e.target.value)} />
          <input type="text" placeholder="Please enter country " value={countryValue} onChange={e => setCountryValue(e.target.value)} />

          <button type="submit" className="document_button" disabled={status1?.DocumentStatus === "loading"} onClick={(e) => gotoRegistrationDocDdPage(e)}>
            {status1?.DocumentStatus === "loading" && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            Next (Step 1/5)
          </button>
        </div>
      </main>
    </>
  );
};

export default RegistrationDocPi;