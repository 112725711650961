import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './fonts/Maison-Mono_Medium.ttf'
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
