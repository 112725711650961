import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import VerifyBlack from "../../../../public/images/verify-black.svg";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { useSelector, useDispatch } from "react-redux";
import { getPickupDetailsAsync, selectPickupData } from "../../deliverySlice"
import CustomModal from "../../../../components/shared/CustomModal"
import { VerifyOrderDeliveryOtpApiAsync } from "../../../dashboard/dashbaordSlice";
import { selectLoginAuth } from "../../../auth/authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import downArrow from "../../../../public/images/downArrow.svg"
import { getAddressFromCoordinates } from "../../../auth/register/AddressMethod";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'

const VerifyPickup = (props) => {
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const location = useLocation()
  const [driverLoc, setDriverLoc] = useState("")
  const locationData = JSON.parse(localStorage.getItem("requesteddata"))
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const auth = useSelector(selectLoginAuth)
  const [middlebody, setmiddlebody] = useState(false);

  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const [driverDistance, setDriverDistance]= useState("")
  const [driverDuration, setDriverDuration]= useState("")
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries: ['places'],
  })
  const pickupData = useSelector(selectPickupData)

  const center = { lat: 30.6574932, lng: 76.727102 }
  const geocodeHandler = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
        getAddressFromCoordinates(position.coords.latitude, position.coords.longitude)
            .then(data => {
              setDriverLoc(data?.formatted_address);
            })
            .catch(err => console.log('error getting address', err));
    });
}
  // async function calculateRoute() {
  //   if (locationData?.order_pick_address === '' || locationData?.order_delivery_address === '') {
  //     return
  //   }
  //   // eslint-disable-next-line no-undef
  //   const directionsService = new google.maps.DirectionsService()
  //   const results = await directionsService.route({
  //     origin: locationData?.order_pick_address,
  //     destination: locationData?.order_delivery_address,
  //     // eslint-disable-next-line no-undef
  //     travelMode: google.maps.TravelMode.DRIVING,
  //   })
  //   console.log(results, "result value")
  //   setDirectionsResponse(results)
  //   setDistance(results.routes[0].legs[0].distance.text)
  //   setDuration(results.routes[0].legs[0].duration.text)
  // }
  async function calculateDriverRoute() {
    if (driverLoc === '' || locationData?.order_pick_address === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: driverLoc,
      destination: locationData?.order_pick_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    console.log(results.routes[0].legs[0], "result value way-to-pickup")
    setDirectionsResponse(results)
    setDriverDistance(results.routes[0].legs[0].distance.text)
    setDriverDuration(results.routes[0].legs[0].duration.text)
  }

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setmiddlebody(current => !current);
  };
  const handleChange = (otp) => {
    setOtp(otp);
  }
  let data = {
    "postData": {
      "order_id": locationData?.order_id,
      "otp": "1234"
    },
    "tokenData": auth?.payload?.token
  }
  const handleVerify = async () => {
    if (!otp || otp.length < 5) {
      toast.error("Please enter your Otp");
      return false
    }
    await dispatch(VerifyOrderDeliveryOtpApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj, "obj data")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.data?.msg)
        }
        history.push('/dashboard/express-delivery/delivery/way-to-delivery')
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   console.log(obj, "obj data")
        //   toastId.current = toast.error(obj?.message)
        // }
      })
    let invalidData = localStorage.getItem("invaliddata")
    if (invalidData) {
      history.push('/dashboard/express-delivery/pickup/verify-pickup')
    }
    // else {
    //   history.push({ pathname: '/dashboard/express-delivery/delivery/way-to-delivery' })
    // }
  }
  useEffect(() => {
    // calculateRoute()
    geocodeHandler()
    calculateDriverRoute()
    dispatch(getPickupDetailsAsync())
  }, [locationData]);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <div className="MapOrders_inner">
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={map => setMap(map)}
            >
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </div>
          {" "}
        </div>

        <div className="pickup-pointbox">
          <div className="deliver-verifybox" onClick={() => handleClick(true)}>
            <h2>Verify Pickup</h2>
            <img className="dropArrow" src={downArrow} onClick="" />
            <p>
              Ask for Order code from shop and <br /> verify it
            </p>
          </div>
          <div className={middlebody ? 'statusBody addClass' : "statusBody"}>
            <div className="verify-box d-flex justify-content-center">
              <OTPInput className='input_digits_'
                value={otp}
                numInputs={5}
                isInputNum={true}
                data-cy="pin-field"
                onChange={handleChange}
              // onChange ={() => {}} 
              // onComplete={(code) => onComplete(code)}
              />
            </div>
            <div className="pickup-mbtn" onClick={handleVerify}>
              <Link to="#">
                Verify
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyPickup