import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../public/images/left-arrow.svg";
import Note from "../../public/images/note.png";

const Settings = (props) => {
  const history = useHistory();

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          Settings
        </h4>
      </div>

      <div className="settings-main">
        <div className="settings-box">
          <h4>Notifications</h4>
          <ul>
            <li>
              Notification
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Notification popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Notification popup
              <label className="switch">
                <input type="checkbox"  />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Notification popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
          </ul>
        </div>

        <div className="settings-box">
          <h4>Distance</h4>
          <ul>
            <li>
              Show in KM
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
          </ul>
        </div>

        <div className="settings-box">
          <h4>Accounts</h4>
          <ul>
            <li>
              Payment popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Payment popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Payment popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
            <li>
              Payment popup
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Settings;