import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../public/images/logo.svg";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { loginAsync, roleAsync, securityPinCheck, selectLoginAuth, selectOtpId, selectRoleStep } from "./authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const Login = (props) => {
    const history = useHistory();
    const [pin, setPin] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const toastId = React.useRef(null)
    const handleChange = (pin) => {
        setPin(pin);
    }
    const phonedetails = JSON.parse(localStorage.getItem("phonedetails"))

    let data = {
        phone_code: phonedetails?.phone_code,
        phone_number: phonedetails?.phone_no,
        password: pin,
        role_slug: "driver"
    }
    const { status1 } = useSelector(state => state.auth)

    const handleOnSubmit = async () => {
        if (!pin || pin.length < 4) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Please enter your PIN");
            }
            return false
        }
        await dispatch(loginAsync(data))
            .then(unwrapResult)
            .then((obj) => {
                toast.success(obj?.msg)
                if (!obj?.payload?.user_profiles?.security_pin) {
                    return history.push('/home')
                }
                if (obj?.payload?.user_profiles?.driver_steps < 5) {
                    return history.push('/register')
                }
                if (obj?.payload?.user_profiles?.driver_steps === 5) {
                    return history.push('/dashboard')
                }
            }
            )
            .catch((obj) => {
                if(obj.message === "Request failed with status code 409"){
                    history.push('/home')
                }
                // console.log(obj,"error obj")
                // if (!toast.isActive(toastId.current)) {
                //     toastId.current = toast.error(obj?.message)
                // }
            })
    }

    useEffect(() => {
        // props.logout();
    }, []);

    return (
        <>
            <div className="logo-login">
                <Link to="#" onClick={(e) => { e.preventDefault() }}>
                    <img src={Logo} alt="logo" />
                </Link>
            </div>
            <div className="login-main">
                <p>Enter your PIN for login</p>
                {/* <div className="pin-box text-center" >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div> */}
                <div className="pin-box d-flex justify-content-center " >
                    <OTPInput className='input_digits_'
                        value={pin}
                        numInputs={4}
                        isInputNum={true}
                        data-cy="pin-field"
                        onChange={handleChange}
                    // onChange ={() => {}} 
                    // onComplete={(code) => onComplete(code)}
                    />
                </div>
                <div className="forgot-pinbtn">
                    <Link to="#" onClick={() => { history.push('/forgot-pin') }}>Forgot PIN?</Link>
                </div>
            </div>
            <div className="bottom-part">
                {/* <p>Don’t have an Account? <Link to="#" onClick={(e) => {history.push('/')}}>Create Now</Link></p> */}
                <button className="btn btn-primary continue" disabled={status1?.loginStatus === "loading"} onClick={() => { handleOnSubmit() }}>
                    {status1?.loginStatus === "loading" && (
                        <span className="spinner-border spinner-border-sm"></span>
                    )}
                    &nbsp;&nbsp;
                    Continue</button>
            </div>
        </>
    )
}

export default Login;
