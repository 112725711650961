import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Gallery from "../../public/images/gallery.png";
import Upload from "../../public/images/upload.svg";
import "react-phone-number-input/style.css";
import { useSelector, useDispatch } from "react-redux";
import { getSubjectApiAsync, getSupportApiAsync, selectSubjectData, selectSupportData, selectUploadedUrlData, supportApiUrlAsync, supportCreateApiAsync } from "../../containers/dashboard/dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";
import moment from 'moment-timezone'
import { unwrapResult } from "@reduxjs/toolkit";

const Support = (props) => {
  const history = useHistory();
  const auth = useSelector(selectLoginAuth)
  const [mySupportFlag, setMySupportFlag] = useState(true);
  const [document, setDocument] = useState("")
  const [subjectValue, setSubjectValue] = useState(null)
  const [description, setDescription] = useState("")
  const [supportFlag, setSupportFlag] = useState(false);
  const supportData = useSelector(selectSupportData);
  const uploadedUrlData = useSelector(selectUploadedUrlData);
  const subjectData = useSelector(selectSubjectData);
  const toastId = React.useRef(null)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    const imageFile = e.target.files[0];
    let params = {
      document: imageFile
    }
    setDocument(URL.createObjectURL(imageFile));
    dispatch(supportApiUrlAsync(params))
  }

  console.log(auth?.payload?.user_profiles, "auth data")
  let fullname = `${auth?.payload?.user_profiles?.firstname} ${auth?.payload?.user_profiles?.lastname}`

  let data = {
    "postData": {
      "subject_id": Number(subjectValue),
      "email": auth?.payload?.user_profiles?.email ? auth?.payload?.user_profiles?.email : "durgadp16@gmail.com",
      "name": fullname,
      "document_url": [
        {
          "url": uploadedUrlData?.data?.payload?.document
        }
      ],
      "notes": description
    },
    "tokenData": auth?.payload?.token
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!subjectValue) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please select your subject");
      }
      return false
    }
    if (!description) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your notes");
      }
      return false
    }
    dispatch(supportCreateApiAsync(data))
      .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        setSubjectValue(null)
        setDescription("")
        history.push("/dashboard/support");
      }
      )
      .catch((obj) => {
      })
    setMySupportFlag(true);
    setSupportFlag(false);
    setDocument("")
  }

  useEffect(() => {
    dispatch(getSubjectApiAsync())
    dispatch(getSupportApiAsync(auth?.payload?.token))
  }, [supportFlag, mySupportFlag]);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            {" "}
            <i className="fas fa-chevron-left"></i>{" "}
          </Link>{" "}
          Support
        </h4>
      </div>

      <div className="support-main">
        <div className="tabs">
          <div className="tab-upper">
            <ul className="tab-links">
              <li className={mySupportFlag ? "active" : ""}>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setMySupportFlag(true);
                    setSupportFlag(false);
                  }}
                >
                  My support ({supportData?.data?.payload?.total ? supportData?.data?.payload?.total : 0})
                </Link>
              </li>
              <li className={supportFlag ? "active" : ""}>
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setMySupportFlag(false);
                    setSupportFlag(true);
                  }}
                >
                  Support
                </Link>
              </li>
            </ul>
          </div>

          <div className="tab-content">
            {mySupportFlag && (
              <>
                <div className={mySupportFlag ? "tab active" : "tab"}>
                  {supportData?.data?.payload?.data?.length ? (
                    <>
                      {supportData?.data?.payload?.data?.map(
                        (value, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div>
                                <div className="support-rewtab1">
                                  <h2 style={{ cursor: "pointer" }} onClick={() => { history.push({ pathname: 'support-details', state: value?.id }) }}>
                                    #{value?.track_number} <span>{value?.status?.name}</span>
                                  </h2>
                                  <div className="support-reqinner">
                                    {/* <span>{value?.subject?.name}</span> */}
                                    <h4>
                                      <strong>{value?.subject?.name}</strong>
                                    </h4>
                                    <p>{value?.notes}</p>
                                  </div>
                                  {
                                    value?.support_comments?.length > 0 ?
                                      value?.support_comments?.map((value, index) => {
                                        return (
                                          <div className="support-reqbtm" key={index}>
                                            <span>Last Respond</span>
                                            <div className="support-reqbtmitem">
                                              <img src={value?.user_id_details?.profile_photo ? value?.user_id_details?.profile_photo : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"} alt="" />
                                              <div className="support-reqbtmcnt">
                                                <h4>{value?.user_id_details?.firstname}{" "}{value?.user_id_details?.lastname}</h4>
                                                <p>
                                                  {moment(value?.created_at).format('MMM DD, YYYY')} {" "}|{" "} {moment(value?.created_at).format('hh:mm A')}
                                                  {value?.lastResponseData?.time}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                      : ""
                                  }
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}

            {supportFlag && (
              <>
                <div className={supportFlag ? "tab active" : "tab"}>
                  <div className="support-rewtab2">
                    <div className="more-help-part">
                      <div className="more-help-upper">
                        <h4>Open new ticket</h4>
                        <p>
                          Our gateway is routed directly to Telstra, with Optus
                          and Vodafone networks service.
                        </p>
                      </div>

                      <div className="more-help-form">
                        <form action="#" method="POST">
                          <input
                            className="user"
                            type="text"
                            value={fullname}
                            disabled
                          // onChange={(e) => setUsername(e.target.value)}
                          />
                          <input
                            className="email"
                            type="text"
                            value={auth?.payload?.user_profiles?.email ? auth?.payload?.user_profiles?.email : "abc@gmail.com"}
                            disabled
                          // onChange={e => setEmail(e.target.value)}
                          />
                          <select name="subject" defaultValue={"Select Subject"} onChange={(e) => setSubjectValue(e.target.value)}>
                            <option value="Select Subject" disabled>Select Subject</option>
                            {subjectData?.data?.payload?.data?.map((value, index) => {
                              return (
                                <option value={value?.id} key={index}>{value?.name}</option>
                              )
                            })}
                          </select>

                          <textarea
                            name="description"
                            cols="30"
                            rows="6"
                            placeholder="Write here"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                          ></textarea>
                          {/* <div className="upload-btn-wrapper upload-btn2 upload-btn-wrapper2">
                            <button className="filebtn">
                              <img src={document ? document : Gallery} alt="" className={document ? "support_img" : ""} />
                              <img src={document ? "" : Upload} alt="" className={document ? "support_img" : ""} />
                              <span>{document ? "" : "Upload your files here"}</span>
                            </button>
                            <input type="file" name="myfile" onChange={e => handleChange(e)} />
                          </div> */}

                          <div class="upload-btn-wrapper upload-btn2 upload-btn-wrapper2">
                            <button class="filebtn">
                              <span class="upload-cnt">
                                {/* <h4>Upload your ID card</h4>
                                <p>We accept only <span>ID card, passport, driving licence</span></p> */}
                              </span>
                              <span class="upload-item">
                                <img src={document ? document : Gallery} alt="" className={document ? "support_img" : ""} />
                                <img src={document ? "" : Upload} alt="" className={document ? "support_img" : ""} />
                                <span>{document ? "" : "Upload your files here"}</span>
                              </span>
                            </button>
                            <input type="file" name="myfile" onChange={e => handleChange(e)} />
                          </div>
                          <button
                            type="button"
                            className="submit-btn"
                            onClick={e => handleSubmit(e)}
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;