import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import LeftArrow from "../../../public/images/left-arrow.svg";
import Camera from "../../../public/images/camera.svg";
import "react-phone-number-input/style.css";
import { toast } from "react-toastify";
import { driverDocumentStepAsync, getUploadUrlAsync1, selectLoginAuth, selectRegisterAuth, selectUploadedUrl1 } from "../authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

const RegistrationDocVid = (props) => {
  const history = useHistory();
  const [registerDate, setRegisterDate] = useState("");
  const [document, setDocument] = useState("");
  const [expiryDate, setExpiryDate] = useState("")
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const uploadImage1 = useSelector(selectUploadedUrl1);
  const registerAuth = useSelector(selectRegisterAuth);
  const auth = useSelector(selectLoginAuth)
  const { status1 } = useSelector(state => state.auth)
  let data = {
    "postData": {
      "driver_steps": "5",
      "wallet_steps": 0,
      "insurance_expiry_date": expiryDate,
      "termcnd_flag": "Y",
      "insurance_expiry_photo": uploadImage1?.payload?.profile_photo,
    },
    "tokenData": registerAuth?.payload?.token ? registerAuth?.payload?.token : auth?.payload?.token
  }

  const gotoRegistrationDocVrdPage = (e) => {
    e.preventDefault();
    history.push("/registration-doc-vrd");
  };
  const handleChangeDate = (e) => {
    setExpiryDate(e.target.value)
    localStorage.setItem("date", e.target.value)
  }

  const gotoRegister3Page = async (e) => {
    e.preventDefault();
    localStorage.removeItem("date")
    
    await dispatch(driverDocumentStepAsync(data))
    .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push("/register3");
        localStorage.removeItem("front")
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
  };
  const handleChange = (e, flag) => {
    if (flag == 'document_1') {
      const imageFileFront = e.target.files[0];
      let formData = new FormData();
      formData.append('document', imageFileFront);
      dispatch(getUploadUrlAsync1(formData))
      setDocument(URL.createObjectURL(imageFileFront));
    }
  }
  let stateDataFront = { imageData: "front", loc: "/registration-doc-vid" }

  useEffect(() => {
    setDocument(localStorage.getItem("front") ? JSON.parse(localStorage.getItem("front")) : Camera)
    setExpiryDate(localStorage.getItem("date") ? localStorage.getItem("date") : "")
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="registration-upper reg-heading2">
          <h4>
            <Link to="/registration-doc-vrd"
              onClick={(e) => gotoRegistrationDocVrdPage(e)}
            >
              <img src={LeftArrow} alt="" />
            </Link>{" "}
            Documents collections
          </h4>
        </div>

        <div className="personal-infomain">
          <h2>Vehicle Insurance details</h2>
          <form action="#" method="POST">
            <label htmlFor="p1">Registration expiry</label>
            {/* <input
              className="date"
              type="date"
              id="p1"
              placeholder="Insurance expiry date"
              value={expiryDate}
              onChange={(e) => handleChangeDate(e)}
            /> */}
            <DatePicker
              selected={registerDate}
              onChange={(date) => setRegisterDate(date)}
              dateFormat="MM/dd/yyyy"
              maxDate={moment().toDate()}
              minDate={moment().subtract(3,'months')._d}
              placeholderText="MM/DD/YYYY"
              className="date"
              autoComplete="off"
            />

            <div className="photo-prt photo-vhcl2">
              <h4>Take a photo of Vehicle insurance</h4>
              <div className="register-file-upload1">
                <img src={document ? document : Camera} alt="" onClick={() => { history.push({ pathname: '/webcam', state: JSON.stringify(stateDataFront) }) }} />
                <input type="file" accept="image/*" name="myfile" onChange={(e) => { handleChange(e, 'document_1') }} />
              </div>
              <p>
                Make sure your name , number and other important information are
                clear and readable.{" "}
              </p>
            </div>
            <button
              type="submit" disabled={status1?.DocumentStatus === "loading"}
              className="document_button"
              onClick={(e) => gotoRegister3Page(e)} 
            >
            {status1?.DocumentStatus === "loading" && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
              Submit (Step 5/5)
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

export default RegistrationDocVid;