import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    registerSendOtp,
    verifyOtp,
    loginApi,
    emailSendApi,
    emailVerifiedApi,
    getCountryApi,
    getDistrictApi,
    getCityApi,
    registerApi,
    getVehicleMakeApi,
    getVehicleModelApi,
    getVehicleVinsApi,
    getVehicleYearsApi,
    getUploadDocumentUrlApi,
    driverDocumentStepApi,
    changePinApi,
    getStateApi,
    roleApi
} from "./authApi";
import { toast } from "react-toastify";
import VerifyEmailOtp from "./register/verifyEmailOtp";

const initialState = {
    user: null,
    otp: null,
    verify: null,
    emailOtp: null,
    emailVerified: null,
    country: [],
    district: [],
    city: [],
    state: [],
    vehicleMake: [],
    vehcileModel: [],
    vehicleYear: [],
    vehicleVins: [],
    driverDocument: null,
    register: null,
    uploadUrl1: null,
    uploadUrl2: null,
    uploadUrl3: null,
    changepin: null,
    role: null,
    securityPin: false,
    status: "idle",
    status1: {
        DocumentStatus: "idle",
        otpStatus: "idle",
        verifyStatus: "idle",
        loginStatus: "idle",
        emailSendStatus: "idle",
        VerifyEmailStatus: "idle",
        cityStatus: "idle",
        countryStatus: "idle",
        registerStatus: "idle",
        changePinStatus: "idle"
    }
};

export const registerOtpSendAsync = createAsyncThunk("auth/registerotp", async (data) => {
    const response = await registerSendOtp(data);
    return response.data;
});
export const registerOtpVerifyAsync = createAsyncThunk("auth/verifyotp", async (data) => {
    const response = await verifyOtp(data);
    return response.data;
});
export const loginAsync = createAsyncThunk("auth/login", async (data) => {
    const response = await loginApi(data);
    return response.data;
});
export const emailSendAsync = createAsyncThunk("auth/emailotp", async (data) => {
    const response = await emailSendApi(data);
    return response.data;
});
export const emailVerifiedAsync = createAsyncThunk("auth/verifiedotp", async (data) => {
    const response = await emailVerifiedApi(data);
    return response.data;
});
export const countryAsync = createAsyncThunk("auth/country", async () => {
    const response = await getCountryApi();
    return response.data;
});
export const districtAsync = createAsyncThunk("auth/district", async (data) => {
    const response = await getDistrictApi(data);
    return response.data;
});
export const stateAsync = createAsyncThunk("auth/state", async (data) => {
    const response = await getStateApi(data);
    return response.data;
});
export const cityAsync = createAsyncThunk("auth/city", async (data) => {
    const response = await getCityApi(data);
    return response.data;
});
export const registerAsync = createAsyncThunk("auth/register", async (data) => {
    const response = await registerApi(data);
    return response.data;
});
export const vehicleMakeAsync = createAsyncThunk("auth/make", async () => {
    const response = await getVehicleMakeApi();
    return response.data;
});
export const vehicleModelAsync = createAsyncThunk("auth/model", async () => {
    const response = await getVehicleModelApi();
    return response.data;
});
export const vehicleVinsAsync = createAsyncThunk("auth/vins", async () => {
    const response = await getVehicleVinsApi();
    return response.data;
});
export const vehicleYearAsync = createAsyncThunk("auth/years", async () => {
    const response = await getVehicleYearsApi();
    return response.data;
});
export const getUploadUrlAsync1 = createAsyncThunk("auth/url", async (data) => {
    const response = await getUploadDocumentUrlApi(data);
    return response.data;
});
export const getUploadUrlAsync2 = createAsyncThunk("auth/url1", async (data) => {
    const response = await getUploadDocumentUrlApi(data);
    return response.data;
});
export const getUploadUrlAsync3 = createAsyncThunk("auth/url2", async (data) => {
    const response = await getUploadDocumentUrlApi(data);
    return response.data;
});
export const driverDocumentStepAsync = createAsyncThunk("auth/driverdocument", async (data) => {
    const response = await driverDocumentStepApi(data.postData, data.tokenData);
    return response.data;
});
export const changePinAsync = createAsyncThunk("auth/changepin", async (data) => {
    const response = await changePinApi(data);
    return response.data;
});
export const roleAsync = createAsyncThunk("auth/roleapi", async (data) => {
    const response = await roleApi(data);
    return response.data;
});


export const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout: (state) => {
            state.user = null;
            state.status = "idle";
            state.otp = null;
            state.emailOtp = null;
            state.emailVerified = null;
            state.country = [];
            state.district = [];
            state.state = [];
            state.city = [];
            state.vehicleMake = [];
            state.vehcileModel = [];
            state.vehicleYear = [];
            state.vehicleVins = [];
            state.driverDocument = null;
            state.register = null;
            state.uploadUrl1 = null;
            state.uploadUrl2 = null;
            state.uploadUrl3 = null;
            state.changepin = null;
            state.role = null;
            toast.success("Successfully Logout")
        },
    },

    extraReducers: {
        [registerOtpSendAsync.pending](state) {
            state.status1.otpStatus = "loading";
        },
        [registerOtpSendAsync.fulfilled](state, { payload }) {
            state.status1.otpStatus = "idle";
            state.otp = payload;
            state.securityPin = payload?.payload?.security_pin_check
        },
        [registerOtpSendAsync.rejected](state, { error }) {
            state.status1.otpStatus = "idle";
            state.otp = [];
        },
        [registerOtpVerifyAsync.pending](state) {
            state.status1.verifyStatus = "loading";
        },
        [registerOtpVerifyAsync.fulfilled](state, { payload }) {
            state.status1.verifyStatus = "idle";
            state.otp = payload;
        },
        [registerOtpVerifyAsync.rejected](state, { error }) {
            state.status1.verifyStatus = "idle";
            state.otp = [];
        },
        [loginAsync.pending](state) {
            state.status1.loginStatus = "loading";
        },
        [loginAsync.fulfilled](state, { payload }) {
            state.status1.loginStatus = "idle";
            state.user = payload;
            state.securityPin = payload?.payload?.user_profiles?.security_pin;
        },
        [loginAsync.rejected](state, { error }) {
            state.status1.loginStatus = "idle";
            state.user = [];
        },
        [emailSendAsync.pending](state) {
            state.status1.emailSendStatus = "loading";
        },
        [emailSendAsync.fulfilled](state, { payload }) {
            state.status1.emailSendStatus = "idle";
            state.emailOtp = payload;
        },
        [emailSendAsync.rejected](state, { error }) {
            state.status1.emailSendStatus = "idle";
            state.emailOtp = [];
        },
        [emailVerifiedAsync.pending](state) {
            state.status1.VerifyEmailStatus = "loading";
        },
        [emailVerifiedAsync.fulfilled](state, { payload }) {
            state.status1.VerifyEmailStatus = "idle";
            state.emailVerified = payload;
        },
        [emailVerifiedAsync.rejected](state, { error }) {
            state.status1.VerifyEmailStatus = "idle";
            state.emailVerified = [];
        },
        [countryAsync.pending](state) {
            state.status1.countryStatus = "loading";
        },
        [countryAsync.fulfilled](state, { payload }) {
            state.status1.countryStatus = "idle";
            state.country = payload;
        },
        [countryAsync.rejected](state, { error }) {
            state.status1.countryStatus = "idle";
            state.country = [];
        },
        [districtAsync.pending](state) {
            state.status = "loading";
        },
        [districtAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.district = payload;
        },
        [districtAsync.rejected](state, { error }) {
            state.status = "idle";
            state.district = [];
        },
        [stateAsync.pending](state) {
            state.status = "loading";
        },
        [stateAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.state = payload;
        },
        [stateAsync.rejected](state, { error }) {
            state.status = "idle";
            state.state = [];
        },
        [cityAsync.pending](state) {
            state.status1.cityStatus = "loading";
        },
        [cityAsync.fulfilled](state, { payload }) {
            state.status1.cityStatus = "idle";
            state.city = payload;
        },
        [cityAsync.rejected](state, { error }) {
            state.status1.cityStatus = "idle";
            state.city = [];
        },
        [registerAsync.pending](state) {
            state.status1.registerStatus = "loading";
        },
        [registerAsync.fulfilled](state, { payload }) {
            state.status1.registerStatus = "idle";
            console.log(payload, "register auth payload")
            state.register = payload;
        },
        [registerAsync.rejected](state, { error }) {
            state.status1.registerStatus = "idle";
            console.log(state.status1.registerStatus, "register auth rejected")
            state.register = [];
        },
        [vehicleMakeAsync.pending](state) {
            state.status = "loading";
        },
        [vehicleMakeAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.vehicleMake = payload;
        },
        [vehicleMakeAsync.rejected](state, { error }) {
            state.status = "idle";
            state.vehicleMake = [];
        },
        [vehicleModelAsync.pending](state) {
            state.status = "loading";
        },
        [vehicleModelAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.vehcileModel = payload;
        },
        [vehicleModelAsync.rejected](state, { error }) {
            state.status = "idle";
            state.vehcileModel = [];
        },
        [vehicleVinsAsync.pending](state) {
            state.status = "loading";
        },
        [vehicleVinsAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.vehicleVins = payload;
        },
        [vehicleVinsAsync.rejected](state, { error }) {
            state.status = "idle";
            state.vehicleVins = [];
        },
        [vehicleYearAsync.pending](state) {
            state.status = "loading";
        },
        [vehicleYearAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.vehicleYear = payload;
        },
        [vehicleYearAsync.rejected](state, { error }) {
            state.status = "idle";
            state.vehicleYear = [];
        },
        [getUploadUrlAsync1.pending](state) {
            state.status = "loading";
        },
        [getUploadUrlAsync1.fulfilled](state, { payload }) {
            state.status = "idle";
            state.uploadUrl1 = payload;
        },
        [getUploadUrlAsync1.rejected](state, { error }) {
            state.status = "idle";
            state.uploadUrl1 = [];
        },
        [getUploadUrlAsync2.pending](state) {
            state.status = "loading";
        },
        [getUploadUrlAsync2.fulfilled](state, { payload }) {
            state.status = "idle";
            state.uploadUrl2 = payload;
        },
        [getUploadUrlAsync2.rejected](state, { error }) {
            state.status = "idle";
            state.uploadUrl2 = [];
        },
        [getUploadUrlAsync3.pending](state) {
            state.status = "loading";
        },
        [getUploadUrlAsync3.fulfilled](state, { payload }) {
            state.status = "idle";
            state.uploadUrl3 = payload;
        },
        [getUploadUrlAsync3.rejected](state, { error }) {
            state.status = "idle";
            state.uploadUrl3 = [];
        },
        [driverDocumentStepAsync.pending](state) {
            state.status1.DocumentStatus = "loading";
        },
        [driverDocumentStepAsync.fulfilled](state, { payload }) {
            state.status1.DocumentStatus = "idle";
            state.driverDocument = payload;
        },
        [driverDocumentStepAsync.rejected](state, { error }) {
            state.status1.DocumentStatus = "idle";
            state.driverDocument = [];
        },
        [changePinAsync.pending](state) {
            state.status1.changePinStatus = "loading";
        },
        [changePinAsync.fulfilled](state, { payload }) {
            state.status1.changePinStatus = "idle";
            state.changepin = payload;
            state.securityPin = true;
        },
        [changePinAsync.rejected](state, { error }) {
            state.status1.changePinStatus = "idle";
            state.changepin = [];
        },
        [roleAsync.pending](state) {
            state.status = "loading";
        },
        [roleAsync.fulfilled](state, { payload }) {
            state.status = "idle";
            state.role = payload;
        },
        [roleAsync.rejected](state, { error }) {
            state.status = "idle";
            state.role = [];
        }
    },
});

export const { logout } = authSlice.actions;

export const selectLoginAuth = (state) => state.auth.user;
export const selectLoginProfile = (state) => state.auth.user;
export const selectRegisterAuth = (state) => state.auth.register;
export const selectOtpId = (state) => state.auth.otp;
export const selectEmailOtpId = (state) => state.auth.emailOtp;
export const selectPhoneVerify = (state) => state.auth.otp;
export const selectCountry = (state) => state.auth.country;
export const selectDistrict = (state) => state.auth.district;
export const selectState = (state) => state.auth.state;
export const selectCity = (state) => state.auth.city;
export const selectMake = (state) => state.auth.vehicleMake;
export const selectModel = (state) => state.auth.vehcileModel;
export const selectVin = (state) => state.auth.vehicleVins;
export const selectYear = (state) => state.auth.vehicleYear;
export const selectUploadedUrl1 = (state) => state.auth.uploadUrl1;
export const selectUploadedUrl2 = (state) => state.auth.uploadUrl2;
export const selectUploadedUrl3 = (state) => state.auth.uploadUrl3;
export const selectEmailVerified = (state) => state.auth.emailVerified;
export const selectChangePin = (state) => state.auth.changepin;
export const selectRoleStep = (state) => state.auth.role
export const securityPinCheck = (state) => state.auth.securityPin

export default authSlice.reducer;