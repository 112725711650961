import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../../public/images/left-arrow.svg";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { driverDocumentStepAsync, selectLoginAuth, selectMake, selectModel, selectRegisterAuth, selectVin, selectYear, vehicleMakeAsync, vehicleModelAsync, vehicleVinsAsync, vehicleYearAsync } from "../authSlice";
import { compose } from "redux";
import { unwrapResult } from "@reduxjs/toolkit";

const RegistrationDocVd = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const { status1 } = useSelector(state => state.auth)
  const registerAuth = useSelector(selectRegisterAuth);
  const [makeValue, setMakeValue] = useState("")
  const [modelValue, setModelValue] = useState("")
  const [vinValue, setVinValue] = useState("")
  const [yearValue, setYearValue] = useState("")
  const [mileageValue, setMileageValue] = useState("")
  const make = useSelector(selectMake)
  const model = useSelector(selectModel)
  const vin = useSelector(selectVin)
  const year = useSelector(selectYear)
  const auth = useSelector(selectLoginAuth)

  let data = {
    "postData": {
      "driver_steps": "3",
      "wallet_steps": 0,
      "vehical_make": makeValue,
      "vehical_year": yearValue,
      "vehical_model": modelValue,
      "vehical_mileage": mileageValue,
      "vehical_vin": vinValue
    },
    "tokenData": registerAuth?.payload?.token ? registerAuth?.payload?.token : auth?.payload?.token
  }

  const gotoRegistrationDocVrdPage = async (e) => {
    e.preventDefault();
    console.log("in a function")
    await dispatch(driverDocumentStepAsync(data))
    .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push("/registration-doc-vrd");
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })

  };

  useEffect(() => {
    dispatch(vehicleMakeAsync())
    dispatch(vehicleModelAsync())
    dispatch(vehicleVinsAsync())
    dispatch(vehicleYearAsync())
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="registration-upper reg-heading2">
          <h4><Link to="/registration_dco-dd"><img src={LeftArrow} alt="" /></Link> Documents collections</h4>
        </div>

        <div className="personal-infomain vehicle-dtmain">
          <h2>Vehicle details</h2>
          <p>Enter your vehicle information</p>
            <div className="vehicle-inneritem">
              <select name="" defaultValue={"Make"} id="districtlist" onChange={(e) => setMakeValue(e.target.value)}>
                <option value="Make" disabled>Make</option>
                {make?.payload?.data?.map((value, index) => {
                  return (
                    <option value={value?.name} key={index}>{value?.name}</option>
                  )
                })}
              </select>
              <select name="" defaultValue={"Model"} id="districtlist" onChange={(e) => setModelValue(e.target.value)}>
                <option value="Model" disabled>Model</option>
                {model?.payload?.data?.map((value, index) => {
                  return (
                    <option value={value?.name} key={index}>{value?.name}</option>
                  )
                })}
              </select>
              <select name="" defaultValue={"Vin"} id="districtlist" onChange={(e) => setVinValue(e.target.value)}>
                <option value="Vin" disabled>Vin</option>
                {vin?.payload?.data?.map((value, index) => {
                  return (
                    <option value={value?.name} key={index}>{value?.name}</option>
                  )
                })}
              </select>
              <select name="" defaultValue={"Year"} id="districtlist" onChange={(e) => setYearValue(e.target.value)}>
                <option value="Year" disabled>Year</option>
                {year?.payload?.data?.map((value, index) => {
                  return (
                    <option value={value?.name} key={index}>{value?.name}</option>
                  )
                })}
              </select>
              <input type="text" name="" id="" placeholder="Mileage" value={mileageValue} onChange={e => setMileageValue(e.target.value)} />
            </div>

            <button type="button" className="document_button" disabled={status1?.DocumentStatus === "loading" }  onClick={(e) => gotoRegistrationDocVrdPage(e)}>
            {status1?.DocumentStatus === "loading" && (
              <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
            Next (Step 3/5)</button>
        </div>
      </main>
    </>
  );
};

export default RegistrationDocVd;