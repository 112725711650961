import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../public/images/left-arrow.svg";
import Profile from "../../public/images/profile-editimg.png";
import Tell from "../../public/images/tell.svg";
import VerifyGreen from "../../public/images/verify-green.svg";
import "react-phone-number-input/style.css";
import { useSelector, useDispatch } from "react-redux";
import { updateProfileAsync, selectUserProfile, profilePhotoAsync, userProfileAsync, selectProfilePhoto } from "../../components/shared/sharedSlice"
import { selectLoginAuth } from "../auth/authSlice";
import { selectLoginProfile } from "../auth/authSlice";
import Spinner from 'react-bootstrap/Spinner';
import { unwrapResult } from "@reduxjs/toolkit";

const MyProfileEdit = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const profileData = useSelector(selectLoginAuth)
  const selectProfile = useSelector(selectUserProfile)
  console.log(selectProfile, "selectprofile")
  const selectLoginUser = useSelector(selectLoginProfile);
  let loginId = selectLoginUser?.payload.id;
  let fulname = `${selectProfile?.payload?.user_profiles?.firstname} `;
 

  const [profile, setProfile] = useState("");
  const [img, setImg] = useState("")
  const [userName, setUserName] = useState(fulname ? fulname : "");
  const [email, setEmail] = useState(selectProfile?.payload?.user_profiles ? selectProfile?.payload?.user_profiles?.email : "");
  const [phoneNo, setPhoneNo] = useState(selectProfile?.payload?.user_profiles ? selectProfile?.payload?.user_profiles?.full_phone_number : "");
  const [document, setDocument] = useState("")
  const updatedProfileImage = useSelector(selectProfilePhoto);
  // console.log("updated profile",updatedProfileImage)

  const handleProfileChange = async(e) => {
    const imageFile = e.target.files[0];
    setDocument(URL.createObjectURL(imageFile));

    let params = {
      profile: imageFile
    }
    await dispatch(profilePhotoAsync(params))
    .then(unwrapResult)
      .then(async (obj) => {console.log(obj,"object data")    
        setImg(obj?.payload?.profile_photo);
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        let param = {
          loginId: loginId,
          token: selectLoginUser.payload.token
          }
        await dispatch(userProfileAsync(param))
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
      
  }

  const onChangeInput = (e, fieldName) => {
    const value = e.target.value;
    if (fieldName == "userName") {
      setUserName(value);
    }
    else if (fieldName == "email") {
      setEmail(value);
    }
    else if (fieldName === "mobile") {
      setPhoneNo(value)
    }
  };

  const handleCheck = () => {
    let params = {
      profile_photo: updatedProfileImage?.payload?.profile_photo,
      firstname:userName,
      loginId: loginId,
      token: selectLoginUser?.payload.token,
    }
    dispatch(updateProfileAsync(params))
    .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push('/dashboard/my-profile')
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
  }

  // console.log(selectLoginUser?.payload?.token, "profileedit token")
  return (
    <>
      <div className="registration-upper reg-heading2 profile-header">
        <h4>
          <Link
            to="/dashboard/my-profile"
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          My Profile{" "}
        </h4>
        <div className="save-btn">
          <Link to='#' onClick={() => handleCheck()} >Save</Link>
        </div>
      </div>

      <div className="profile-main">
        <div className="profile-image">
          <div className="upload-btn-wrapper upload-btn-wrapper2">
            <div className="profile-imageprt">
              <img src={img ? img : selectProfile?.payload?.user_profiles?.profile_photo ? selectProfile?.payload?.user_profiles?.profile_photo : Profile} alt="Profile" />
              <input type="file" name="myfile" style={{ opacity: '0' }} onChange={(e) => handleProfileChange(e)} />
            </div>
          </div>
        </div>

        <div className="profile-box">
          <div className="profbox-inner">
            <h4>{profile?.rating}</h4>
            <p>Rating</p>
          </div>
          <div className="profbox-inner">
            <h4>
              <span>{profile?.deliveries}</span>
            </h4>
            <p>Deliveries</p>
          </div>
        </div>

        <div className="profile-info">
          <form action="#" method="POST">
            <label htmlFor="p1">Username</label>
            <input
              className="user-name"
              type="text"
              id="p1"
              placeholder="username"
              value={userName ? userName : "dummyusername"}
              onChange={(e) => { onChangeInput(e, 'userName') }}
            />
            <label htmlFor="p2">Email address</label>
            <input
              disabled
              className="email"
              type="email"
              id="p2"
              placeholder="your@eamil.com"
              value={email ? email : "admin1@yopmail.com"}
              onChange={(e) => { onChangeInput(e, 'email') }}
            // onChange={e => setEmail(e.target.value)}
            />
            <label htmlFor="">Phone number</label>
            {/* <img src={Tell} alt="" /> {}{" "}
            <img className="verify-grn" src={VerifyGreen} alt="" /> */}
            <h4><img src={Tell} alt="" />
              <input disabled className="p-edit" type="text" id="r2" value={phoneNo ? phoneNo : "123456789"} name="mobile" onChange={(e) => { onChangeInput(e, 'mobile') }} />
              <img className="verify-grn" src={VerifyGreen} alt="" />
            </h4>
          </form>
        </div>
      </div>
    </>
  );
};

export default MyProfileEdit;
