import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Location from "../../public/images/track-location-2.png";
import Shop from "../../public/images/shop.png";
import LogoIcon from "../../public/images/logo-icon_2.svg";

const Home1 = (props) => {
  const history = useHistory();

  useEffect(() => {
    //
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96485.45894957401!2d-72.83509575903798!3d40.912005312792395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85e2c0949dc13%3A0x1f8329c3de1c24ee!2sCalverton%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1667997278940!5m2!1sen!2sin"
            width="573"
            height="700"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />{" "}
        </div>
        

        <div className="track-lcitem">
          <img src={Location} alt="" />
        </div>

        <div className="pickup-pointbox pointbox-blue2">
          <div className="accept-mdlupper">
            <h2>
              Pickup<span>BB2609-0822-RS0002</span>
            </h2>
          </div>

          <div className="accptmdl-uprtimeline">
            <ul>
              <li>
                <div className="tmmin-cnt">
                  <p>
                    4 mins <span>1.1 m</span>
                  </p>
                </div>
                <div className="logot-icon2">
                  <img src={LogoIcon} alt="" />
                </div>
              </li>
              <li>
                <div className="shop-tml">
                  <img src={Shop} alt="" />
                </div>
              </li>
            </ul>
          </div>

          <div className="accept-mdltimline">
            <div className="verticle-timeline">
              <ul>
                <li>
                  <h4>
                    Pickup point <span>with in next 10 min</span>
                  </h4>
                  <p>3878 Angus Road New York, NY 10048</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="pickup-mbtn">
            <Link to="#" onClick={() => { history.push('/dashboard/one-hour-delivery/pickup/verify-pickup') }}>Mark as pickup</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home1;

// <div className="reqaccpt-exprs text-center hwd-uprbtn">
//           <span>
//             1 hour window delivery accepted: <strong>2</strong>
//           </span>
//         </div>