import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Location from "../../public/images/track-location-2.png";
import Circle from "../../public/images/circle-2.png";
import LogoIcon from "../../public/images/logo-icon_2.svg";
import Timeline from "../../public/images/timeline-prof.png";
import Order from "../../public/images/order-prof.png";
import Tell from "../../public/images/tell.svg";
import Chat from "../../public/images/chat.svg";
import { useSelector, useDispatch } from "react-redux";
import { getMyDeliveryAsync, selectMyDeliveryData } from "./dashbaordSlice"
import Shop from "../../public/images/shop.png";

const PickupRequestOne = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const deliveryData = useSelector(selectMyDeliveryData)

  useEffect(() => {
    dispatch(getMyDeliveryAsync())
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96485.45894957401!2d-72.83509575903798!3d40.912005312792395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e85e2c0949dc13%3A0x1f8329c3de1c24ee!2sCalverton%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1667997278940!5m2!1sen!2sin"
            width="573"
            height="700"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            tabIndex="0"
          />{" "}
        </div>
        <div className="reqaccpt-exprs text-center delvpop-upbtnUpdated">
          <span>
            1 hour window delivery: <strong>0</strong>
          </span>
        </div>
        <div className="track-lcitem track-lctdl3">
          <img src={Location} alt="" />
        </div>
        <div className="pickup-pointbox">
          <h3 className="midContent_">
            You can accept 2 delivery request
          </h3>
          <div className="modal-body">
            {/* <div className="delvpop-upbtn">
                <span>
                  1 hour window delivery: <strong>0</strong>
                </span>
              </div> */}
            <div className="modal-mainprt">
              <div className="accept-mdlbox windlv-1hour">
                <div className="accept-mdlupper dlvacpt-hrwind">
                  <h2 className="highlightSubhead_">
                    1 Hour delivery window <span>New delivery request</span>
                  </h2>
                  <img src={Circle} alt="" />
                </div>

                <div className="accptmdl-uprtimeline accptmdl-uprtimelineUpdated">
                  <ul>
                    <li>
                      <div className="tmmin-cnt">
                        <p>
                          10 mins <span>3 m</span>
                        </p>
                      </div>
                      <div className="logot-icon2">
                        <img src={LogoIcon} alt="" />
                      </div>
                    </li>
                    <li>
                      <div className="tmmin-cnt">
                        <p>
                          27 mins <span>16 m</span>
                        </p>
                      </div>
                      <div className="shop-tml">
                        <img src={Shop} alt="" />
                      </div>
                    </li>
                    <li>
                      <div className="prof-tml2 prof-tml2Updated">
                        <img src={Timeline} alt="" />
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="accept-mdltimline">
                  <div className="verticle-timeline">
                    <ul>
                      <li>
                        <h4>
                          Pickup point <span> with in next 59 min</span>
                        </h4>
                        <p>1189 Main Street, Seattle, WA 98161</p>
                      </li>
                      <li className="dark-cnt">
                        <h4 className="dark-thd">
                          Delivery point <span>By 12:00 pm</span>
                        </h4>
                        <p>1189 Main Street, Seattle, WA 98161</p>
                      </li>
                    </ul>
                  </div>
                </div>

                <ul className="mdlbtm-links">
                  <li>
                    <Link to="#">
                      Cancel
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="accept-btn">
                      Accept
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PickupRequestOne;
