import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../public/images/logo.svg";
import LockBlue from "../../public/images/lock-blue.png";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { registerOtpVerifyAsync, selectOtpId } from "./authSlice";
import {  useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

const VerifyPhone = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [otp, setOtp] = useState("")
  let otpId = useSelector(selectOtpId)
  console.log(otpId,"verify phone")
  let data1 = {
    "id": Number(otpId?.payload?.id),
    "otp": Number(otp),
    "role_id": 3,
    "isAlreadyCheck": true
  }

  const handleChange = (otp) => {
    setOtp(otp);
    // history.push('/re-enter-new-pin')
  }
  const onSubmit = () => {
    if (!otp || otp.length < 5) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your Otp");
      }
      return false
    }
    dispatch(registerOtpVerifyAsync(data1))
    .then(unwrapResult)
      .then((obj) => {console.log(obj,"object data")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push('/set-new-pin')
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
    
  }

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="lock-part2">
          <img src={LockBlue} alt="" />
        </div>

        <div className="verify-part">
          <h2>
            Verify your <strong>phone number</strong>
          </h2>
          <p>Enter OTP code here</p>
          <div className="verify-box d-flex justify-content-center ">
            <OTPInput className='input_digits_'
              value={otp}
              numInputs={5} 
              isInputNum={true}
              data-cy="pin-field"
              onChange={handleChange}
            // onChange ={() => {}} 
            // onComplete={(code) => onComplete(code)}
            />
          </div>
        </div>

        <div className="bottom-part verift-btn">
          <p>
            Didn’t receive Code? <Link to="#">Resend</Link>
          </p>
          <Link
            to="#"
            className="bottom-btn"
            onClick={onSubmit}
            style={{ textDecoration: "none" }}
          >
            Verify
          </Link>
        </div>
      </main>
    </>
  );
};

export default VerifyPhone;