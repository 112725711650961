import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LogoIcon from "../../public/images/logo_icon.svg";
import LeftArrow from "../../public/images/left-arrow.svg";
import Dot from "../../public/images/dot.svg";
import Transfer from "../../public/images/transfer.svg";
import Money from "../../public/images/money.svg";
import DeliveryParcel from "../../public/images/delivery-parcel.svg";
import RightCircle from "../../public/images/bi_arrow-down-right-circle.svg";
import ParcelBox from "../../public/images/parcel-box.png";
import ArrowLeftUp from "../../public/images/arrow-leftup.png";
import "react-phone-number-input/style.css";
import { useSelector, useDispatch } from "react-redux";
import { getWalletDataAsync, selectWalletData } from "../../containers/dashboard/dashbaordSlice"
import CustomModal from "../../components/shared/CustomModal"
import InstantTransferModal from "./InstantTransferModal";
import NormalTransfer from "./NormalTransfer";
import PaymentMethodSelect from "./PaymentMethodSelect";
import AddCreditCard from "./AddCreditCard";
import PaymentMethod from "./PaymentMethod";

const Wallet = (props) => {
  const history = useHistory();

  const [dotClickedFlag, setDotClickedFlag] = useState();
  const dispatch = useDispatch()
  const walletData = useSelector(selectWalletData);
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    flag: ""
  });

  useEffect(() => {
    dispatch(getWalletDataAsync())
    setDotClickedFlag(false);
  }, []);

  const openSmallModal = (e) => {
    e.preventDefault();
    setDotClickedFlag(!dotClickedFlag);
  };

  const smallModalStyleInactive = () => {
    return {
      width: "175px",
      background: "#FFFFFF",
      padding: "10px 16px",
      boxShadow: "4px 4px 8px rgb(0 0 0 / 10%)",
      borderRadius: "10px",
      position: "absolute",
      right: "-5px",
      top: "24px",
      display: "none",
    };
  };

  const smallModalStyleActive = () => {
    return {
      width: "175px",
      background: "#FFFFFF",
      padding: "10px 16px",
      boxShadow: "4px 4px 8px rgb(0 0 0 / 10%)",
      borderRadius: "10px",
      position: "absolute",
      right: "-5px",
      top: "24px",
      display: "block",
    };
  };

  const handleModalDetails = (data) => {
    // console.log(data,"data")
    if (data == 'normal_transfer' || data == 'instant_transfer' || data == "add_card" || data == "payment_method") {
      setModalDetail({ show: true, flag: data }); setKey(Math.random());
    }
  }

  const handleOnCloseModal = () => {
    setModalDetail({ show: false });
    setKey(Math.random());
  }

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          JBR Wallet
        </h4>
      </div>

      <div className="wallet-main">
        <div className="ballance-box">
          <div className="ballance-upper">
            <img src={LogoIcon} alt="" />
            <div className="balance-cnt">
              <h4>
                Available Balance{" "}
                <Link to="#">
                  <img
                    src={Dot}
                    alt=""
                    onClick={(e) => {
                      openSmallModal(e);
                    }}
                  />
                </Link>
              </h4>
              <p>JBR {walletData?.balance}</p>

              <div
                className={dotClickedFlag ? "balance-dropdown" : ""}
                style={
                  dotClickedFlag
                    ? smallModalStyleActive()
                    : smallModalStyleInactive()
                }
              >
                <Link
                  to="#"
                  onClick={() => { setModalDetail({ show: true, flag: 'instant_transfer', }); setKey(Math.random()); }}
                >
                  <img src={Transfer} alt="" /> Transfer
                </Link>{" "}
                <br />
                <Link
                  to="#"
                  onClick={() => { setModalDetail({ show: true, flag: 'payment_selected', }); setKey(Math.random()); }}
                >
                  <img src={Money} alt="" /> Payment method
                </Link>
              </div>
              
            </div>
          </div>

          <div className="balance-itminr">
            <h4>
              <img src={DeliveryParcel} alt="" /> Earn
            </h4>
            <h4>
              <img src={RightCircle} alt="" /> Withdrawal
            </h4>
          </div>
        </div>

        <div className="delivery-history">
          <h2>Delivery history</h2>
          <div className="delivery-histitem">
            <div className="delivery-histbox">
              <h4>
                <i className="fas fa-trophy"></i> {walletData?.bronze}
              </h4>
              <p>Bronze</p>
            </div>
            <div className="delivery-histbox dlbg2">
              <h4>{walletData?.delivered}</h4>
              <p>Delivered</p>
            </div>
            <div className="delivery-histbox dlbg3">
              <h4>{walletData?.returned}</h4>
              <p>Returned</p>
            </div>
          </div>
        </div>

        <div className="trans-history">
          <h2>Transection history</h2>
          <ul>
            {walletData?.transactionHistory.length ? (
              <>
                {walletData?.transactionHistory.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li>
                        <div className="trans-histleft">
                          <img
                            src={
                              value.state === "Delivery Fee"
                                ? ParcelBox
                                : ArrowLeftUp
                            }
                            alt=""
                          />
                          <div className="trans-histcnt">
                            <h4>{value.state}</h4>
                            <p>{value.date}</p>
                          </div>
                        </div>
                        <div className="trans-histright">
                          <p>JBR {value.amount}</p>
                        </div>
                      </li>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>
      </div>

      <CustomModal
        key={key}
        show={modalDetail.show}
        size="lg"
        // backdrop="static"
        title={modalDetail.title}
        fullwidth_payment={true}
        child={
          modalDetail.flag == 'instant_transfer' ?
            <InstantTransferModal walletData={walletData} close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
            : (modalDetail.flag == "normal_transfer") ? <NormalTransfer walletData={walletData} close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
              : (modalDetail.flag == "payment_selected") ? <PaymentMethodSelect close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
                : (modalDetail.flag == "add_card") ? <AddCreditCard close={() => handleOnCloseModal()} details={(e) => handleModalDetails(e)} />
                  : (modalDetail.flag == "payment_method") ? <PaymentMethod close={() => handleOnCloseModal()} /> : ""
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default Wallet;