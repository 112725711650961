import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../../public/images/left-arrow.svg";
import Camera from "../../../public/images/camera.svg";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { driverDocumentStepAsync, getUploadUrlAsync1, getUploadUrlAsync2, selectLoginAuth, selectRegisterAuth, selectUploadedUrl1, selectUploadedUrl2 } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const RegistrationDocDd = (props) => {
  const history = useHistory();
  const { status1 } = useSelector(state => state.auth)
  const [documentFront, setDocumentFront] = useState("");
  const [documentBack, setDocumentBack] = useState("");
  const [drivingLicenseNo, setDrivingLicenseNo] = useState("")
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const registerAuth = useSelector(selectRegisterAuth);

  const uploadImage1 = useSelector(selectUploadedUrl1)
  const uploadImage2 = useSelector(selectUploadedUrl2)
  const auth = useSelector(selectLoginAuth)


  const gotoRegistrationDocPiPage = (e) => {
    e.preventDefault();
    history.push("/registration-doc-pi");
  };

  const handleChangeDate = (e) => {
    setDrivingLicenseNo(e.target.value)
    localStorage.setItem("date", e.target.value)
  }

  const gotoRegistrationDocVdPage = async (e) => {
    e.preventDefault();
    localStorage.removeItem("date")
    if (!drivingLicenseNo) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your Driving License No.");
      }
      return;
    }
    if (!documentFront) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Upload Front side of Driving License");
      }
      return;
    }
    if (!documentBack) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please Upload Back side of Driving License");
      }
      return;
    }

    let data = {
      "postData": {
        "driver_steps": "2",
        "wallet_steps": 0,
        "driving_licence_no": drivingLicenseNo,
        "driving_license_front_photo": uploadImage1?.payload?.profile_photo,
        "driving_license_back_photo": uploadImage2?.payload?.profile_photo
      },
      "tokenData": registerAuth?.payload?.token ? registerAuth?.payload?.token : auth?.payload?.token
    }
    
    await dispatch(driverDocumentStepAsync(data))
    .then(unwrapResult)
      .then((obj) => {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push("/registration-doc-vd");
        localStorage.removeItem("front")
        localStorage.removeItem("back")
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })

  };

  // console.log(documentFront,"documentFront")

  const handleChange = (e, flag) => {
    if (flag == 'document_1') {
      const imageFileFront = e.target.files[0];
      let formData = new FormData();
      formData.append('document', imageFileFront);
      dispatch(getUploadUrlAsync1(formData))
      setDocumentFront(URL.createObjectURL(e.target.files[0]));
    }
    if (flag == 'document_2') {
      const imageFileBack = e.target.files[0]
      // console.log(imageFileBack,"response back")
      let formData = new FormData();
      formData.append('document', imageFileBack);
      dispatch(getUploadUrlAsync2(formData))
      setDocumentBack(URL.createObjectURL(imageFileBack));
    }
  }

  let stateDataFront = { imageData: "front", loc: "/registration-doc-dd" }
  let stateDataBack = { imageData: "back", loc: "/registration-doc-dd" }
  useEffect(() => {
    setDocumentFront(localStorage.getItem("front") ? JSON.parse(localStorage.getItem("front")) : "")
    setDocumentBack(localStorage.getItem("back") ? JSON.parse(localStorage.getItem("back")) : "")
    setDrivingLicenseNo(localStorage.getItem("date") ? localStorage.getItem("date") : "")
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="registration-upper reg-heading2">
          <h4>
            <Link to="#" onClick={(e) => gotoRegistrationDocPiPage(e)}>
              <img src={LeftArrow} alt="" />
            </Link>{" "}
            Documents collections
          </h4>
        </div>

        <div className="personal-infomain">
          <h2>Driving details</h2>
          <form action="#" method="POST">
            <label htmlFor="p1">Driving licence</label>
            <input type="text" id="p1" placeholder="Driving license number" value={drivingLicenseNo} onChange={e => handleChangeDate(e)} />

            <div className="photo-prt">
              <h4>
                Take a photo of Driving licence <span>(Front side) </span>
              </h4>
              <div className="register-file-upload register-file-upload1">
                <img src={documentFront ? documentFront : Camera} alt="" />
                {/* onClick={() => { history.push({ pathname: '/webcam', state: JSON.stringify(stateDataFront) }) }} */}
                <input type="file" accept="image/*" name="myfile" onChange={(e) => { handleChange(e, 'document_1') }} />
              </div>
              <h4>
                Take a photo of Driving licence <span>(Backside)</span>
              </h4>
              <div className="register-file-upload register-file-upload1">
                <img src={documentBack ? documentBack : Camera} alt=""/>
                <input type="file" name="myfile" onChange={(e) => { handleChange(e, 'document_2') }} />
              </div>
              <p>
                Make sure your name , number and other important information are
                clear and readable.{" "}
              </p>
            </div>
            <button
              type="submit" disabled={status1?.DocumentStatus === "loading"}
              className="document_button"
              onClick={(e) => gotoRegistrationDocVdPage(e)}
            >
            {status1?.DocumentStatus === "loading" && (
              <span className="spinner-border spinner-border-sm"></span>
                )}
                &nbsp;&nbsp;
              Next (Step 2/5)
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

export default RegistrationDocDd;