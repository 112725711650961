import axios from "axios";
import getTrackData from "../../db/app-tracker.json";
import getMyDelivery from "../../db/my-deliveries.json"
import getRewards from "../../db/reward.json"
import getSupportDetailsData from "../../db/support-details.json"
import { toast } from "react-toastify";
// import getSupportData from "../../db/support.json";
import getWalletData from "../../db/wallet.json";
// A mock function to mimic making an async request for data
export function getAppTrackData() {
  return getTrackData
}
export function getMyDeliveryData() {
  return getMyDelivery
}
export function getRewardData() {
  return getRewards
}
export function getSupportDetails() {
  return getSupportDetailsData
}
// export function getSupport(){
//     return getSupportData
// }
export function getWallet() {
  return getWalletData
}

export function getSupportApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_SUPPORT}api/v1/supports/user`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function getSubjectApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_SUPPORT}api/v1/subjects?page=1&limit=10`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function supportDocumentUrlApi(data) {
  var formData = new FormData();
  formData.append('document', data.document);
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_SUPPORT}api/v1/supports/document`, formData, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function createSupportRequestApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_SUPPORT}api/v1/supports`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        toast.error(error?.response?.data?.msg)
        reject(error)
      })
  )
}

export function getdeliveryRequestApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/driver-deliveries?status=Pending`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getdeliveryRequestDeliveredApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/driver-deliveries?status=Delivered`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getdeliveryRequestReturnedApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/driver-deliveries?status=Returned`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function VerifyOrderDeliveryOtpApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/verify-otp`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        localStorage.removeItem("invaliddata")
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
        localStorage.setItem("invaliddata", error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function VerifyDeliveryOtpApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/verify-otp`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function VerifyDeliveryCancelOtpApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/cancel-order/verify-otp`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function getChecklistApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/check_list`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getRideHistoryApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/ride_points/get-ride-points`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getAppTrackerDataApi(token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/app-tracker`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getTripDataApi(data,token) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/driver-deliveries-by-interval?interval=${data}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}

export function addFuelBillApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_ORDER_URL}api/v1/fuel_bills/bulk-create`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        // toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function changeDeliveryStatusApi(data, token) {
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_ORDER_URL}api/v1/order_delivery/change-status`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
      })
  )
}