import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../public/images/left-arrow.svg";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { unwrapResult } from "@reduxjs/toolkit";
const SetNewPin = (props) => {
  const history = useHistory();

  const [otp, setOtp] = useState("");
  const toastId = React.useRef(null)
  const handleChange = (otp) => {
    setOtp(otp);
  }

  // console.log(data.otp,"pppppppppppp")
  const handleNext = async() => {
    localStorage.setItem("setpin",otp)
    if(!otp && otp.length < 4){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid pin")
      }
      return
    }
    
    // const invalidOtp = await localStorage.getItem("invalidotp")
    history.push({pathname:'/re-enter-new-pin', state: otp})
    
  }
  
  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <div className="pin-upper" style={{ position: "relative" }}>
        <Link to={"/home"}>
          <img src={LeftArrow} alt="" />
        </Link>
        <span
          style={{ position: "absolute", left: "45px", fontWeight: "600" }}
        >
          Set Up PIN
        </span>
        <Link to={"/"}>
          <div className="cancel-btn">Cancel</div>
        </Link>
      </div>

      <div className="pin-main">
        <p>Enter your PIN</p>
        <div className="verify-box d-flex justify-content-center ">
          <OTPInput className='input_digits_'
          value={otp}
          numInputs={4} 
          isInputNum={true}
          data-cy="pin-field"
          onChange={handleChange}
            // onChange ={() => {}} 
            // onComplete={(code) => onComplete(code)}
          />
        </div>
      </div>

      <div className="bottom-part">
        <Link to="#" className="bottom-btn" onClick={handleNext}>Next</Link>
      </div>
    </>
  );
};

export default SetNewPin;