import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// import { getDeliveryData } from "../../../../actions/delivery/delivery/way-to-delivery";
import Phone from "../../../public/images/phone.svg";
import Profile from "../../../public/images/profile.png";
import Profile2 from "../../../public/images/profile-2.png";
import Camera from "../../../public/images/camera.png";
import Mic from "../../../public/images/mic.svg";
import SendCircle from "../../../public/images/send-circle.svg";

const Message = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;

  return (
    <>
      <main className="main-wrapper" style={{ position: "absolute" }}>
        <div className="registration-upper reg-heading2 chatbox-header">
          <h4>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                history.goBack()
                // state?.fromWaitingOnDoor
                //   ? history.goBack()
                //   : history.push("/dashboard/express-delivery/delivery/way-to-delivery");
              }}
            >
              <i className="fas fa-chevron-left"></i>{" "}
            </Link>{" "}
            Henry B. Jacobs
          </h4>
          <div className="phone-icon">
            <Link to="#">
              <img src={Phone} alt=""/>
            </Link>
          </div>
        </div>

        <div className="message-main">
          <div className="date-box">
            <span>Today | April 20, 2022</span>
          </div>

          <div className="chat-rightprt">
            <div className="chat-rightcnt">
              <p>Our gateway is routed directly to Telstra, with Optus an.</p>
              <span>12:30 pm</span>
            </div>
            <img src={Profile} alt="" />
          </div>
          <div className="chat-leftprt">
            <img src={Profile2} alt="" />
            <div className="chat-leftcnt">
              <p>
                Our gateway is routed directly to Telstra, with Optus and
                Vodafone networks serviced through various Australian
                aggregators
              </p>
              <span>12:30 pm</span>
            </div>
          </div>
        </div>

        <div className="chat-bottom">
          <form action="#">
            <div className="chat-btmitem">
              <div className="chat-btminner">
                <input type="text" placeholder="Type here..." />
                <div className="chat-btmicon">
                  <button type="button">
                    <img src={Camera} alt=""/>
                  </button>
                  <button type="button">
                    <img src={Mic} alt="" />
                  </button>
                </div>
              </div>
              <div className="send-btn">
                <button type="button">
                  <img src={SendCircle} alt="" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default Message;