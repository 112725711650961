import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../public/images/logo.svg";
import Welcome from "../../public/images/welcoming.png";
import RightArrow from "../../public/images/right-arrow_white.svg";

const Home = (props) => {
  const history = useHistory();

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="onboarding-main">
          <h2>Welcome</h2>

          <div className="welcoming-prt">
            <img src={Welcome} alt="" />
          </div>

          <div className="onboard-btmbox">
            <h4>You can start delivery!</h4>
            <p>
              Yeah! as simple as it. All you need to set up password for next{" "}
                <Link to="#">login</Link>
            </p>
            <Link to={"/set-new-pin"}>
              <div className="setup-pinbtn">
                <span style={{ color: "white" }}>Set up New PIN </span>
                <img src={RightArrow} alt="" />
              </div>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default Home;
