import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import PrivacyProfile from "../../../public/images/privacy-profile.png";
import VerifyBlack from "../../../public/images/verify-black.svg";
import Logo from "../../../public/images/logo.svg";
import "react-phone-number-input/style.css";
import { useDispatch,useSelector } from "react-redux";
import { registerAsync,registerStatus,selectRegisterAuth } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";


const CreateProfile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [firstName, setFirstName] = useState("")
  const [middleName, setMiddleName] = useState("")
  const [lastName, setLastName] = useState("")
  const [middleShow, setMiddleShow] = useState(true)
  const { status1 } = useSelector(state => state.auth)
  const register = useSelector(selectRegisterAuth)
  let data1 = JSON.parse(localStorage.getItem("phonedetails"))
  let emailData = localStorage.getItem("email")
  const registerPage = async() => {
    const dataM = {
      "firstname": firstName,
      "lastname": lastName,
      "middlename": middleName,
      "role_id": 3,
      "phone_code": data1?.phone_code,
      "email": emailData,
      "phone_no": data1?.phone_no,
      "state": props?.location?.state?.state,
      "district": props?.location?.state?.district,
      "city": props?.location?.state?.city
    }
    const data = {
      "firstname": firstName,
      "lastname": lastName,
      "email": emailData,
      "role_id": 3,
      "phone_code": data1?.phone_code,
      "phone_no": data1?.phone_no,
      "state": props?.location?.state?.state,
      "district": props?.location?.state?.district,
      "city": props?.location?.state?.city
    }
    let regName = /^[a-zA-Z]+$/;
    if (!firstName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your First name");
      }
      return false;
    }
    else if (firstName.length < 3) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Firstname should be of 3 digits");
      }
      return false;
    }
    else if(!regName.test(firstName)){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Invalid Firstname given.");
      }
      return false;
    }
    if (middleShow) {
      if (middleName.length < 3) {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Middlename should be of 3 digits");
        }
        return false;
      }
      else if(!regName.test(middleName)){
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Invalid Middlename given.");
        }
        return false;
      }
    }
    
    if (!lastName) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter your Last name");
      }
      return false;
    }
    else if (lastName.length < 3) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Lastname should be of 3 digits");
      }
      return false;
    }
    else if(!regName.test(lastName)){
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Invalid Lastname given.");
      }
      return false;
    }
    await dispatch(registerAsync(middleShow ? dataM : data))
    .then(unwrapResult)
      .then((obj) => {
        console.log(obj,"createprofile data")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push("/register");
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })

  };

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="logo-part">
          <Link to="#">
            <img src={Logo} alt="" />
          </Link>
        </div>

        <div className="delivery-driver cprof-prt">
          <img src={PrivacyProfile} alt="" />
        </div>

        <div className="driver-form">
          <form action="#" method="POST">
            <h2>
              Create<span>your Profile</span>
            </h2>
            <label htmlFor="n1">First name</label>
            <input type="text" id="n1" placeholder="Legal first name" value={firstName} onChange={e => setFirstName(e.target.value)} />
            <label htmlFor="n2">Middle name</label>
            <input type="text" id="n2" placeholder="Legal middle name" value={middleName} onChange={e => setMiddleName(e.target.value)} disabled={!middleShow} />
            <div className="form-group checkbox-item">
              <input type="checkbox" id="check" checked={!middleShow} onChange={() => setMiddleShow(prev => !prev)} />
              <label htmlFor="check">I don’t have middle name</label>
            </div>
            <label htmlFor="n3">Last name</label>
            <input type="text" id="n3" placeholder="Legal last name" value={lastName} onChange={e => setLastName(e.target.value)} />
            <button
              type="button" disabled={status1?.registerStatus === "loading" }
              onClick={(e) => registerPage(e)} 
            >
            {status1?.registerStatus === "loading" && (
              <span className="spinner-border spinner-border-sm"></span>
          )}
          &nbsp;&nbsp;
              Next
            </button>
          </form>
        </div>
      </main>
    </>
  );
};

export default CreateProfile;

// const handleCountry=(id)=>
// {
//   const dt = district?.payload?.data.filter(x => x.country_id == id)
//   setEmpty(dt)
// }
// const handleDistrict=(id)=>
// {
//   const dt = city?.payload?.data.filter(x => x.country_id == id)
//   setCityValue(dt)
// }
// const handleCity=()=>
// {
//   // const dt = city?.payload?.data.filter(x => x.country_id == id)
//   // setCityValue(dt)
// }
// [[[[[[[]]]]]]]
// <select className="country-name" defaultValue={"Select Country"} name="country" id="countrylist" onChange={(e) => handleCountry(e.target.value)}>
//               <option value="Select Country" disabled>Select Country</option>
//               {country?.payload?.data &&
//                 country ?.payload?.data !== undefined ?
//                 country?.payload?.data?.map((value, index) => {
//                 return (
//                   <option value={value?.id} key={index}>{value?.name}</option>
//                 )
//               }): "no country"}
//             </select>
//             <select name="district" defaultValue={"Select District"} id="districtlist" onChange={(e) => handleDistrict(e.target.value)} >
//               <option value="Select District" disabled>Select District</option>
//               {district?.payload?.data &&
//                 district ?.payload?.data !== undefined ?
//                 district?.payload?.data?.map((value, index) => {
//                 return (
//                   <option value={value?.id} key={index}>{value?.name}</option>
//                 )
//               }): "No state"}
//             </select>
//             <select name="city" defaultValue={"Select City"} id="citylist" onChange={(e) => handleCity(e.target.value)} >
//               <option value="Select City" disabled>Select City</option>
//               {
//                 city?.payload?.data &&
//                 city ?.payload?.data !== undefined ?
//                 city?.payload?.data?.map((value, index) => {
//                 return (
//                   <option value={value?.id} key={index}>{value?.name}</option>
//                 )
//               }) : "No city"}
//             </select>