import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Camera from "../../public/images/camera.png";
import Note from "../../public/images/note.png";
import "react-phone-number-input/style.css";
import { getUploadUrlAsync1, selectLoginAuth } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { addFuelBillApiAsync } from "./dashbaordSlice";

const AppTrackerList2 = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const auth = useSelector(selectLoginAuth)
  const toastId = React.useRef(null)
  const [formValues, setFormValues] = useState([{ group_price: "", group_gallons: "", group_image: "" }])

  console.log(formValues, "form values")
  let handleChange = (i, e, flag) => {
    if (flag == 'document_1') {
      const imageFileFront = e.target.files[0];
      let formData = new FormData();
      formData.append('document', imageFileFront);
      dispatch(getUploadUrlAsync1(formData))
        .then(unwrapResult)
        .then((obj) => {
          let newFormValues = [...formValues];
          newFormValues[i][e.target.name] = obj?.payload?.profile_photo
          setFormValues(newFormValues);
        }
        )
        .catch((obj) => {
        })
    } else {
      let newFormValues = [...formValues];
      newFormValues[i][e.target.name] = e.target.value;
      setFormValues(newFormValues);
    }
  }

  let addFormFields = () => {
    setFormValues([...formValues, {}])
  }

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
  }

  const handleAddFuel = () => {
    let wasNull = false;
    for (var i in formValues) {
      if (formValues[i].group_price == "" || formValues[i].group_gallons == "" || formValues[i].group_image == "" || Object.keys(formValues[i]).length == 0) {
        wasNull = true
      }
    }
    if (wasNull) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("All Fields are mandatory to field")
      }
    }
    else {
      let data = {
        "postData": {
          "data": formValues
        },
        "tokenData": auth?.payload?.token
      }
      dispatch(addFuelBillApiAsync(data))
        .then(unwrapResult)
        .then((obj) => {
          history.push('/dashboard/app-tracker-list')
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.success(obj?.msg)
          }
        }
        )
        .catch((obj) => {
          // if (!toast.isActive(toastId.current)) {
          //   toastId.current = toast.error(obj?.message)
          // }
        })
      // toast.success("Data correctly field")
    }
    // history.push('/dashboard/app-tracker-list')
  }

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              history.push("/dashboard/app-tracker-list");
            }}
          >
            <i className="fas fa-times"></i>
          </Link>{" "}
          Add Fuel
        </h4>
      </div>

      <div className="fuel-main">
        <div className="fuel-upper">
          <h2>Add your fuel bills for calculating cost</h2>
          <p>You can add multiple bills at once</p>
          <p>
            <span>Instruction:</span> Take a clear photo of your bill. Make sure
            photo is clear and readable
          </p>
        </div>

        {/* <div className="fuel-box">
          <div className="fuel-inner">
            <span>$36.22</span>
            <span>12.99</span>
            <Link to="#">
              <img src={Camera} alt="" />
            </Link>
          </div>
          <div className="sales-card">
            <img src={Note} alt="" />
          </div>
        </div> */}

        <div className="">
          {formValues.map((element, index) => {
            return (
              <div className="fuel-box" key={index}>
                <div className="fuel-inner m-2">
                  <input type="text" name="group_price" value={element.group_price || ""} placeholder="Total Price" onChange={e => handleChange(index, e)} />
                  <input type="text" name="group_gallons" value={element.group_gallons || ""} placeholder="Gallons filled" onChange={e => handleChange(index, e)} />
                  <label className="upload_file">
                    <input type="file" name="group_image" className="d-none" onChange={e => handleChange(index, e, 'document_1')} />
                    <img src={Camera} alt="" />
                  </label>
                  {
                    index ?
                      <div className="btn_add_del" onClick={() => removeFormFields(index)}>
                        <i class="fa fa-trash"></i>
                      </div>
                      : null
                  }
                </div>
                {element?.group_image ?
                  <div className="sales-card">
                    <img src={element.group_image} alt="" />
                  </div>
                  : ""
                }
              </div>
            )
          })}

          <div className="text-center pt-2 btn_add_del mb-4" onClick={() => addFormFields()}>
            <i className="fa fa-plus-circle"></i>
          </div>

        </div>
        <div className="addfuel-btn" onClick={handleAddFuel}>
          <Link to="#">Add Fuel</Link>
        </div>
      </div>
    </>
  );
};

export default AppTrackerList2;