import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Message from "../../../public/images/message.svg";
import Doc1 from "../../../public/images/document-1.svg";
import Doc3 from "../../../public/images/document-3.svg";
import Doc4 from "../../../public/images/document-4.svg";
import Doc5 from "../../../public/images/document-5.svg";
import Doc6 from "../../../public/images/document-6.svg";
import Doc7 from "../../../public/images/document-7.svg";
import Logo from "../../../public/images/logo.svg";
import "react-phone-number-input/style.css";

const Register4 = (props) => {
  const history = useHistory();

  const gotoRegistrationAgreementPage = (e) => {
    e.preventDefault();
    history.push("/registration-agreement");
  };

  useEffect(() => {
    setTimeout(() => {
        history.push('/home')
        toast.success("Form is successfully submit")
      }, 2000)
      
  }, []);

  return (
    <>
      <main className="main-wrapper">
            <div className="registration-upper">
                <div className="logo-reg">
                    <Link to="#">
                        <img src={Logo} alt="" />
                    </Link>
                </div>
                <div className="message-icon">
                    <Link to="#">
                        <img src={Message} alt="" />
                    </Link>
                </div>
            </div>

            <div className="registration-mainprt">
                <h2>Application progress</h2>
                <div className="document-box document-activebox">
                    <div className="document-item">
                        <img src={Doc1} alt="" />
                        <div className="document-cnt">
                            <h4>Guidelines</h4>
                            <p>This is a custom stage.</p>
                        </div>
                    </div>
                    <div className="document-btn document-submitted">
                        <Link to="#">Submitted & Saved</Link>
                    </div>
                </div>
                <div className="document-box document-activebox">
                    <div className="document-item">
                        <img src={Doc5} alt="" />
                        <div className="document-cnt">
                            <h4>Document Collection</h4>
                            <p>This stage is about submitting a form like Quiz or Service agreement.</p>
                        </div>
                    </div>
                    <div className="document-btn document-submitted">
                        <Link to="#">Submitted & Saved</Link>
                    </div>
                </div>
                <div className="document-box document-activebox">
                    <div className="document-item">
                        <img src={Doc6} alt="" />
                        <div className="document-cnt">
                            <h4>Agreement for Terms and Conditions</h4>
                            <p>This stage is to confirm the agreement for the terms and conditions.</p>
                        </div>
                    </div>
                    <div className="document-btn document-submitted">
                        <Link to="#">Submitted & Saved</Link>
                    </div>
                </div>
                <div className="document-box document-reviewbox">
                    <div className="document-item">
                        <img src={Doc7} alt="" />
                        <div className="document-cnt">
                            <h4>Document Review</h4>
                            <p>This stage is about submitting a form like Quiz or Service agreement.</p>
                        </div>
                    </div>
                    <div className="document-btn">
                        <Link to="#">In Review</Link>
                    </div>
                </div>
            </div>
        </main>
    </>
  );
};

export default Register4;