import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getAppTrackData,
    getMyDeliveryData,
    getRewardData,
    getSupportDetails,
    getWallet,
    getSupportApi,
    getSubjectApi,
    supportDocumentUrlApi,
    createSupportRequestApi,
    getdeliveryRequestApi,
    VerifyOrderDeliveryOtpApi,
    VerifyDeliveryOtpApi,
    getdeliveryRequestDeliveredApi,
    getdeliveryRequestReturnedApi,
    VerifyDeliveryCancelOtpApi,
    getChecklistApi,
    getRideHistoryApi,
    getAppTrackerDataApi,
    getTripDataApi,
    addFuelBillApi,
    changeDeliveryStatusApi
} from "./dashboardApi";

const initialState = {
    track: null,
    mydelivery: null,
    reward: null,
    supportdetails: null,
    support: [],
    wallet: null,
    subject: [],
    supportImage: null,
    supportCreate: [],
    deliveryRequest: [],
    orderVerifyOtp: null,
    deliveryVerifyOtp: null,
    deliveryCancelOtp: null,
    deliveryCancelledOtp: null,
    deliveryDelivered: [],
    deliveryReturned: [],
    checkList: [],
    rideHistory: [],
    appTracker: [],
    tripData: [],
    fuelBill: [],
    deliveryStatus: null,
    status: {
        track: 'idle',
        mydelivery: 'idle',
        reward: 'idle',
        supportdetails: 'idle',
        support: 'idle',
        wallet: 'idle',
        subject: 'idle',
        supportImage: 'idle',
        supportCreate: 'idle',
        deliveryRequest: 'idle',
        orderVerifyOtp: 'idle',
        deliveryVerifyOtp: 'idle',
        deliveryCancelOtp: 'idle',
        deliveryCancelledOtp: 'idle',
        deliveryDelivered: 'idle',
        deliveryReturned: 'idle',
        checkList: 'idle',
        rideHistory: 'idle',
        appTracker: 'idle',
        tripData: 'idle',
        fuelBill: 'idle',
        deliveryStatus: 'idle'
    },
};

export const getTrackDataAsync = createAsyncThunk("dashboard/track", async (data) => {
    const response = await getAppTrackData();
    return response;
});
export const getMyDeliveryAsync = createAsyncThunk("dashboard/mydelivery", async (data) => {
    const response = await getMyDeliveryData();
    return response;
});
export const getRewardAsync = createAsyncThunk("dashboard/reward", async (data) => {
    const response = await getRewardData();
    return response;
});
export const getSupportDetailsAsync = createAsyncThunk("dashboard/supportdetails", async (data) => {
    const response = await getSupportDetails();
    return response;
});
export const getWalletDataAsync = createAsyncThunk("dashboard/wallet", async (data) => {
    const response = await getWallet();
    return response;
});
export const getSupportApiAsync = createAsyncThunk("dashboard/supportlist", async (data) => {
    const response = await getSupportApi(data);
    return response;
});
export const getSubjectApiAsync = createAsyncThunk("dashboard/subject", async () => {
    const response = await getSubjectApi();
    return response;
});
export const supportApiUrlAsync = createAsyncThunk("dashboard/spporturl", async (data) => {
    const response = await supportDocumentUrlApi(data);
    return response;
});
export const supportCreateApiAsync = createAsyncThunk("dashboard/supportcreate", async (data) => {
    const response = await createSupportRequestApi(data.postData, data.tokenData);
    return response;
});
export const getdeliveryRequestApiAsync = createAsyncThunk("dashboard/deliveryrequest", async (data) => {
    const response = await getdeliveryRequestApi(data);
    return response;
});
export const VerifyOrderDeliveryOtpApiAsync = createAsyncThunk("dashboard/verifyorderotp", async (data) => {
    const response = await VerifyOrderDeliveryOtpApi(data.postData, data.tokenData);
    return response;
});
export const VerifyDeliveryOtpApiAsync = createAsyncThunk("dashboard/verifydeliveryotp", async (data) => {
    const response = await VerifyDeliveryOtpApi(data.postData, data.tokenData);
    return response;
});
export const getdeliveryRequestDeliveredApiAsync = createAsyncThunk("dashboard/getdeliveryRequestDeliveredApiAsync", async (data) => {
    const response = await getdeliveryRequestDeliveredApi(data);
    return response;
});
export const getdeliveryRequestReturnedApiAsync = createAsyncThunk("dashboard/getdeliveryRequestReturnedApiAsync", async (data) => {
    const response = await getdeliveryRequestReturnedApi(data);
    return response;
});
export const VerifyDeliveryCancelOtpApiAsync = createAsyncThunk("dashboard/VerifyDeliveryCancelOtpApiAsync", async (data) => {
    const response = await VerifyDeliveryCancelOtpApi(data.postData, data.tokenData);
    return response;
})
export const getChecklistApiAsync = createAsyncThunk("dashboard/getChecklistApi", async (data) => {
    const response = await getChecklistApi(data);
    return response;
})
export const getRideHistoryApiAsync = createAsyncThunk("dashboard/getRideHistoryApiAsync", async (data) => {
    const response = await getRideHistoryApi(data);
    return response;
})
export const getAppTrackerDataApiAsync = createAsyncThunk("dashboard/getAppTrackerDataApi", async (data) => {
    const response = await getAppTrackerDataApi(data);
    return response;
})
export const getTripDataApiAsync = createAsyncThunk("dashboard/getTripDataApiAsync", async (data) => {
    const response = await getTripDataApi(data.postData, data.tokenData);
    return response;
})
export const addFuelBillApiAsync = createAsyncThunk("dashboard/addFuelBillApiAsync", async (data) => {
    const response = await addFuelBillApi(data.postData, data.tokenData);
    return response;
})
export const changeDeliveryStatusApiAsync = createAsyncThunk("dashboard/changeDeliveryStatusApi", async (data) => {
    const response = await changeDeliveryStatusApi(data.postData, data.tokenData);
    return response;
})

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,

    extraReducers: {
        [getTrackDataAsync.pending](state) {
            state.status.track = "loading";
        },
        [getTrackDataAsync.fulfilled](state, { payload }) {
            state.status.track = "idle";
            state.track = payload;
        },
        [getTrackDataAsync.rejected](state, { error }) {
            state.status.track = "idle";
            state.track = [];
        },
        [getMyDeliveryAsync.pending](state) {
            state.status.mydelivery = "loading";
        },
        [getMyDeliveryAsync.fulfilled](state, { payload }) {
            state.status.mydelivery = "idle";
            state.mydelivery = payload;
        },
        [getMyDeliveryAsync.rejected](state, { error }) {
            state.status.mydelivery = "idle";
            state.mydelivery = [];
        },
        [getRewardAsync.pending](state) {
            state.status.reward = "loading";
        },
        [getRewardAsync.fulfilled](state, { payload }) {
            state.status.reward = "idle";
            state.reward = payload;
        },
        [getRewardAsync.rejected](state, { error }) {
            state.status.reward = "idle";
            state.reward = [];
        },
        [getSupportDetailsAsync.pending](state) {
            state.status.supportdetails = "loading";
        },
        [getSupportDetailsAsync.fulfilled](state, { payload }) {
            state.status.supportdetails = "idle";
            state.supportdetails = payload;
        },
        [getSupportDetailsAsync.rejected](state, { error }) {
            state.status.supportdetails = "idle";
            state.supportdetails = [];
        },
        [getWalletDataAsync.pending](state) {
            state.status.wallet = "loading";
        },
        [getWalletDataAsync.fulfilled](state, { payload }) {
            state.status.wallet = "idle";
            state.wallet = payload;
        },
        [getWalletDataAsync.rejected](state, { error }) {
            state.status.wallet = "idle";
            state.wallet = [];
        },
        [getSupportApiAsync.pending](state) {
            state.status.support = "loading";
        },
        [getSupportApiAsync.fulfilled](state, { payload }) {
            state.status.support = "idle";
            state.support = payload;
        },
        [getSupportApiAsync.rejected](state, { error }) {
            state.status.support = "idle";
            state.support = [];
        },
        [getSubjectApiAsync.pending](state) {
            state.status.subject = "loading";
        },
        [getSubjectApiAsync.fulfilled](state, { payload }) {
            state.status.subject = "idle";
            state.subject = payload;
        },
        [getSubjectApiAsync.rejected](state, { error }) {
            state.status.subject = "idle";
            state.subject = [];
        },
        [supportApiUrlAsync.pending](state) {
            state.status.supportImage = "loading";
        },
        [supportApiUrlAsync.fulfilled](state, { payload }) {
            state.status.supportImage = "idle";
            state.supportImage = payload;
        },
        [supportApiUrlAsync.rejected](state, { error }) {
            state.status.supportImage = "idle";
            state.supportImage = [];
        },
        [supportCreateApiAsync.pending](state) {
            state.status.supportCreate = "loading";
        },
        [supportCreateApiAsync.fulfilled](state, { payload }) {
            state.status.supportCreate = "idle";
            state.supportCreate = payload;
        },
        [supportCreateApiAsync.rejected](state, { error }) {
            state.status.supportCreate = "idle";
            state.supportCreate = [];
        },
        [getdeliveryRequestApiAsync.pending](state) {
            state.status.deliveryRequest = "loading";
        },
        [getdeliveryRequestApiAsync.fulfilled](state, { payload }) {
            state.status.deliveryRequest = "idle";
            state.deliveryRequest = payload;
        },
        [getdeliveryRequestApiAsync.rejected](state, { error }) {
            state.status.deliveryRequest = "idle";
            state.deliveryRequest = [];
        },
        [VerifyOrderDeliveryOtpApiAsync.pending](state) {
            state.status.orderVerifyOtp = "loading";
        },
        [VerifyOrderDeliveryOtpApiAsync.fulfilled](state, { payload }) {
            state.status.orderVerifyOtp = "idle";
            state.orderVerifyOtp = payload;
        },
        [VerifyOrderDeliveryOtpApiAsync.rejected](state, { error }) {
            state.status.orderVerifyOtp = "idle";
            state.orderVerifyOtp = [];
        },
        [VerifyDeliveryOtpApiAsync.pending](state) {
            state.status.deliveryVerifyOtp = "loading";
        },
        [VerifyDeliveryOtpApiAsync.fulfilled](state, { payload }) {
            state.status.deliveryVerifyOtp = "idle";
            state.deliveryVerifyOtp = payload;
        },
        [VerifyDeliveryOtpApiAsync.rejected](state, { error }) {
            state.status.deliveryVerifyOtp = "idle";
            state.deliveryVerifyOtp = [];
        },
        [getdeliveryRequestDeliveredApiAsync.pending](state) {
            state.status.deliveryDelivered = "loading";
        },
        [getdeliveryRequestDeliveredApiAsync.fulfilled](state, { payload }) {
            state.status.deliveryDelivered = "idle";
            state.deliveryDelivered = payload;
        },
        [getdeliveryRequestDeliveredApiAsync.rejected](state, { error }) {
            state.status.deliveryDelivered = "idle";
            state.deliveryDelivered = [];
        },
        [getdeliveryRequestReturnedApiAsync.pending](state) {
            state.status.deliveryReturned = "loading";
        },
        [getdeliveryRequestReturnedApiAsync.fulfilled](state, { payload }) {
            state.status.deliveryReturned = "idle";
            state.deliveryReturned = payload;
        },
        [getdeliveryRequestReturnedApiAsync.rejected](state, { error }) {
            state.status.deliveryReturned = "idle";
            state.deliveryReturned = [];
        },
        [VerifyDeliveryCancelOtpApiAsync.pending](state) {
            state.status.deliveryCancelledOtp = "loading";
        },
        [VerifyDeliveryCancelOtpApiAsync.fulfilled](state, { payload }) {
            state.status.deliveryCancelledOtp = "idle";
            state.deliveryCancelledOtp = payload;
        },
        [VerifyDeliveryCancelOtpApiAsync.rejected](state, { error }) {
            state.status.deliveryCancelledOtp = "idle";
            state.deliveryCancelledOtp = [];
        },
        [getChecklistApiAsync.pending](state) {
            state.status.checkList = "loading";
        },
        [getChecklistApiAsync.fulfilled](state, { payload }) {
            state.status.checkList = "idle";
            state.checkList = payload;
        },
        [getChecklistApiAsync.rejected](state, { error }) {
            state.status.checkList = "idle";
            state.checkList = [];
        },
        [getRideHistoryApiAsync.pending](state) {
            state.status.rideHistory = "loading";
        },
        [getRideHistoryApiAsync.fulfilled](state, { payload }) {
            state.status.rideHistory = "idle";
            state.rideHistory = payload;
        },
        [getRideHistoryApiAsync.rejected](state, { error }) {
            state.status.rideHistory = "idle";
            state.rideHistory = [];
        },
        [getAppTrackerDataApiAsync.pending](state) {
            state.status.appTracker = "loading";
        },
        [getAppTrackerDataApiAsync.fulfilled](state, { payload }) {
            state.status.appTracker = "idle";
            state.appTracker = payload;
        },
        [getAppTrackerDataApiAsync.rejected](state, { error }) {
            state.status.appTracker = "idle";
            state.appTracker = [];
        },
        [getTripDataApiAsync.pending](state) {
            state.status.tripData = "loading";
        },
        [getTripDataApiAsync.fulfilled](state, { payload }) {
            state.status.tripData = "idle";
            state.tripData = payload;
        },
        [getTripDataApiAsync.rejected](state, { error }) {
            state.status.tripData = "idle";
            state.tripData = [];
        },
        [changeDeliveryStatusApiAsync.pending](state) {
            state.status.deliveryStatus = "loading";
        },
        [changeDeliveryStatusApiAsync.fulfilled](state, { payload }) {
            state.status.deliveryStatus = "idle";
            state.deliveryStatus = payload;
        },
        [changeDeliveryStatusApiAsync.rejected](state, { error }) {
            state.status.deliveryStatus = "idle";
            state.deliveryStatus = [];
        }
    },
});

export const selectTrackData = (state) => state.dashboard.track;
export const selectMyDeliveryData = (state) => state.dashboard.mydelivery;
export const selectMyRewardsData = (state) => state.dashboard.reward;
export const selectSupportDetailsData = (state) => state.dashboard.supportdetails;
export const selectSupportData = (state) => state.dashboard.support;
export const selectWalletData = (state) => state.dashboard.wallet;
export const selectSubjectData = (state) => state.dashboard.subject;
export const selectUploadedUrlData = (state) => state.dashboard.supportImage;
export const selectDeliveryRequestData = (state) => state.dashboard.deliveryRequest;
export const selectDeliveryRequestDeliveredData = (state) => state.dashboard.deliveryDelivered;
export const selectDeliveryRequestReturnedData = (state) => state.dashboard.deliveryReturned;
export const selectChecklistData = (state) => state.dashboard.checkList;
export const selectRideHistoryData = (state) => state.dashboard.rideHistory;
export const selectAppTrackerData = (state) => state.dashboard.appTracker;
export const selectTripData = (state) => state.dashboard.tripData;
export const selectFuelBill = (state) => state.dashboard.fuelBill;
export default dashboardSlice.reducer;