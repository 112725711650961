import React, { useEffect } from 'react'
import TrackImg from "../../../app/public/images/track-location.png";
import DtMap from "../../../app/public/images/dtmap.png";
import TimelineProf from "../../../app/public/images/timeline-prof.png";
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getMyDeliveryAsync, selectMyDeliveryData } from "./dashbaordSlice"

const MyDeliveryHistory = () => {
    const history = useHistory();

    const deliveryData = useSelector(selectMyDeliveryData)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getMyDeliveryAsync())
    }, []);
    return (
        <div className="main-wrapper">
            <div className="registration-upper reg-heading2">
                <h4><Link to='/dashboard/my-deliveries'><i className="fas fa-chevron-left"></i></Link> #XD1256P67</h4>
                <div className="delivered-btn">
                    <span>Delivered</span>
                </div>
            </div>

            <div className="delivery-dtlmain">
                <div className="delivery-dtlmap">
                    <img src={DtMap} alt="" />
                    <span><img src={TrackImg} alt="" /></span>
                </div>

                <div className="dlttrack-history">
                    <div className="dlttrack-box">
                        <div className="tracking-upperdt">
                            <h2>Tracking History</h2>
                        </div>

                        <div className="tracking-timelinedt">
                            <div className="verticle-timeline">
                                <ul>
                                    <li>
                                        <h4>Verification</h4>
                                        <p>Waiting for OTP</p>
                                    </li>
                                    <li className="check">
                                        <h4 className="dark-thd">Delivery point</h4>
                                        <div className="timeline-prof">
                                            <img src={TimelineProf} alt="" />
                                            <div className="timeline-profcnt">
                                                <h2>{deliveryData?.delivered?.deliveryData[0]?.delivery?.recipientName}</h2>
                                                <p>{deliveryData?.delivered?.deliveryData[0]?.delivery?.address}</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="check">
                                        <h4>Pickup point</h4>
                                        <p>{deliveryData?.delivered?.deliveryData[0]?.pickup?.address}</p>
                                        <p> {deliveryData?.delivered?.deliveryData[0]?.pickup?.date} | {deliveryData?.delivered?.deliveryData[0]?.pickup?.time}</p>
                                    </li>
                                    <li className="check">
                                        <h4>Accepted</h4>
                                        <p>21 Jun, 2022  |   10:30 am</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyDeliveryHistory