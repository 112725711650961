import { Switch, Route } from 'react-router-dom';
import * as Layouts from './../app/layouts';
import * as Containers from './../app/containers';

function Router() {

  return (
    <Switch>
      {/* Auth Routes*/}
      <Layouts.NonPrivateRoute exact path="/login" component={Containers.Login} />
      <Layouts.NonPrivateRoute exact path="/home" component={Containers.Home} />
      <Layouts.NonPrivateRoute exact path="/forgot-pin" component={Containers.ForgotPin} />
      <Layouts.NonPrivateRoute exact path="/verify-phone" component={Containers.VerifyPhone} />
      <Layouts.NonPrivateRoute exact path="/set-new-pin" component={Containers.SetNewPin} />
      <Layouts.NonPrivateRoute exact path="/re-enter-new-pin" component={Containers.ReEnterNewPin} />
      <Layouts.NonPrivateRoute exact path="/webcam" component={Containers.WebCam} />
      <Layouts.NonPrivateRoute exact path="/" component={Containers.RegisterPhone} />
      <Layouts.NonPrivateRoute exact path="/verify-phone-on-register" component={Containers.VerifyPhoneOnRegister} />
      <Layouts.NonPrivateRoute exact path="/register-email" component={Containers.RegisterEmail} />
      <Layouts.NonPrivateRoute exact path="/verify-email" component={Containers.VerifyEmailOtp} />
      <Layouts.NonPrivateRoute exact path="/area-code-with-state" component={Containers.AreaCodeWithState} />
      <Layouts.NonPrivateRoute exact path="/create-profile" component={Containers.CreateProfile} />
      <Layouts.NonPrivateRoute exact path="/register" component={Containers.Register} />
      <Layouts.NonPrivateRoute exact path="/registration-guideline" component={Containers.RegistrationGuideline} />
      <Layouts.NonPrivateRoute exact path="/register2" component={Containers.Register2} />
      <Layouts.NonPrivateRoute exact path="/registration-doc-pi" component={Containers.RegistrationDocPi} />
      <Layouts.NonPrivateRoute exact path="/registration-doc-dd" component={Containers.RegistrationDocDd} />
      <Layouts.NonPrivateRoute exact path="/registration-doc-vd" component={Containers.RegistrationDocVd} />
      <Layouts.NonPrivateRoute exact path="/registration-doc-vrd" component={Containers.RegistrationDocVrd} />
      <Layouts.NonPrivateRoute exact path="/registration-doc-vid" component={Containers.RegistrationDocVid} />
      <Layouts.NonPrivateRoute exact path="/register3" component={Containers.Register3} />
      <Layouts.NonPrivateRoute exact path="/registration-agreement" component={Containers.RegistrationAgreement} />
      <Layouts.NonPrivateRoute exact path="/register4" component={Containers.Register4} />
      <Layouts.PrivateRoute exact path="/dashboard" component={Containers.Dashboard} />
      <Layouts.PrivateRoute exact path="/express-pickup-request" component={Containers.pickupRequest} />
      <Layouts.PrivateRoute exact path="/1hour-pickup-request" component={Containers.pickupRequestOne} />
      <Layouts.PrivateRoute exact path="/dashboard/home-1" component={Containers.Home1} />
      <Layouts.PrivateRoute exact path="/dashboard/home-2" component={Containers.Home2} />
      <Layouts.PrivateRoute exact path="/dashboard/home-3" component={Containers.Home3} />
      <Layouts.PrivateRoute exact path="/dashboard/home-4" component={Containers.Home4} />
      <Layouts.PrivateRoute exact path="/dashboard/my-deliveries" component={Containers.MyDeliveries} />
      <Layouts.PrivateRoute exact path="/dashboard/my-deliveries-history" component={Containers.MyDeliveriesHistory} />
      <Layouts.PrivateRoute exact path="/dashboard/wallet" component={Containers.Wallet} />
      <Layouts.PrivateRoute exact path="/dashboard/my-profile" component={Containers.MyProfile} />
      <Layouts.PrivateRoute exact path="/dashboard/my-profile-edit" component={Containers.MyProfileEdit} />
      <Layouts.PrivateRoute exact path="/dashboard/reward" component={Containers.Reward} />
      <Layouts.PrivateRoute exact path="/dashboard/app-tracker" component={Containers.AppTracker} />
      <Layouts.PrivateRoute exact path="/dashboard/app-tracker-list" component={Containers.AppTrackerList} />
      <Layouts.PrivateRoute exact path="/dashboard/app-tracker-list-2" component={Containers.AppTrackerList2} />
      <Layouts.PrivateRoute exact path="/dashboard/settings" component={Containers.Settings} />
      <Layouts.PrivateRoute exact path="/dashboard/support" component={Containers.Support} />
      <Layouts.PrivateRoute exact path="/dashboard/support-details" component={Containers.SupportDetails} />
      <Layouts.PrivateRoute exact path="/dashboard/offline" component={Containers.Offline} />
      <Layouts.PrivateRoute exact path="/dashboard/online" component={Containers.Online} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/pickup/way-to-pickup" component={Containers.WayToPickup} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/pickup/verify-pickup" component={Containers.VerifyPickup} />
      <Layouts.PrivateRoute exact path="/dashboard/one-hour-delivery/pickup/verify-pickup" component={Containers.VerifyPickupOneHourDelivery} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/delivery/way-to-delivery" component={Containers.WayToDelivery} />
      <Layouts.PrivateRoute exact path="/dashboard/delivery/message" component={Containers.Message} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/delivery/waiting-on-door" component={Containers.WaitingOnDoor} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/return/way-to-return" component={Containers.WayToReturn} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/return/verify-return" component={Containers.VerifyReturn} />
      <Layouts.PrivateRoute exact path="/dashboard/one-hour-delivery/delivery/waiting-on-door" component={Containers.WaitingOnDoorOneHourDelivery} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/return/way-to-return-2" component={Containers.WayToReturn2} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/delivery/verify-delivery" component={Containers.VerifyDelivery} />
      <Layouts.PrivateRoute exact path="/dashboard/one-hour-delivery/delivery/verify-delivery" component={Containers.VerifyDeliveryOneHour} />
      <Layouts.PrivateRoute exact path="/dashboard/express-delivery/cancel/waiting-on-door" component={Containers.WaitingOnDoorExpressCancel} />
      <Layouts.PrivateRoute exact path="/dashboard/one-hour-delivery/cancel/waiting-on-door" component={Containers.WaitingOnDoorOneHourCancel} />
      <Route component={Containers.NotFound} />
    </Switch>
  );
}

export default Router;