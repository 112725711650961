import React,{ useEffect } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Online1 from '../../../app/containers/dashboard/online'
import Offline1 from '../../../app/containers/dashboard/offline';
import { Offline, Online } from "react-detect-offline";
import useNetworkStatus from '../../../utilities/UseNetworkStatus';

const DashboardLayout = (props) => {
    // let condition = navigator.onLine ? 'online' : 'offline';
    // const checkOnlineStatus = () => {

    //             if (condition === 'online') {
    //         console.log('ONLINE');
    //         fetch('https://www.google.com/', { // Check for internet connectivity
    //             mode: 'no-cors',
    //         })
    //             .then(() => {
    //                 console.log('CONNECTED TO INTERNET');
    //                 condition = 'online'
    //             }).catch(() => {
    //                 console.log('INTERNET CONNECTIVITY ISSUE');
    //             })

    //     } else {
    //         console.log('OFFLINE')
    //         condition = 'offline'
    //     }
    // }

    // useEffect(() => {
    //     checkOnlineStatus()
    // }, [navigator.onLine])

    const { isOnline } = useNetworkStatus();
  
    return (
        <>
            {(!isOnline) ?
                <main className="main-wrapper">
                    <Offline1 />
                </main>
                : <main className="main-wrapper">
                    <Header {...props} />
                    <Sidebar {...props} />
                    {props.children}
                </main>
            }
        </>
    )
}

export default DashboardLayout;