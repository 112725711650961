import React, { useState, useEffect } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Location from "../../../../public/images/track-location-2.png";
import Circle from "../../../../public/images/circle-2.png";
import LogoIcon from "../../../../public/images/logo-icon_2.svg";
import Timeline from "../../../../public/images/timeline-prof.png";
import Order from "../../../../public/images/order-prof.png";
import Tell from "../../../../public/images/tell.svg";
import Chat from "../../../../public/images/chat.svg";
import { useSelector, useDispatch } from "react-redux";
import { getDeliveryDetailsAsync, selectDeliveryData } from "../../deliverySlice"
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { changeDeliveryStatusApiAsync } from "../../../dashboard/dashbaordSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectLoginAuth } from "../../../auth/authSlice";
import downArrow from "../../../../public/images/downArrow.svg"
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'

const WayToDelivery = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const location = useLocation()
  const deliveryData = useSelector(selectDeliveryData)
  const auth = useSelector(selectLoginAuth)
  const locationData = JSON.parse(localStorage.getItem("requesteddata"))
  const toastId = React.useRef(null)
  const [middlebody, setmiddlebody] = useState(false);

  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBbytCk92gm3MK3Mrs_374RDKf4bz0X1ck",
    libraries: ['places'],
  })
  const center = { lat: 30.6574932, lng: 76.727102 }
  console.log(distance,"distance")
  async function calculateRoute() {
    if (locationData?.order_pick_address === '' || locationData?.order_delivery_address === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: locationData?.order_pick_address,
      destination: locationData?.order_delivery_address,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    console.log(results, "result value")
    setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setmiddlebody(current => !current);
  };
  const handleCompletePcikup = () => {
    let data1 = {
      "postData": {
        "order_id": locationData?.order_id,
        "status": "Pickup"
      },
      "tokenData": auth?.payload?.token
    }
    dispatch(changeDeliveryStatusApiAsync(data1))
      .then(unwrapResult)
      .then((obj) => {
        console.log(obj, "obj data")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.data?.msg)
        }
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.data?.message)
        // }
      })
    history.push({ pathname: '/dashboard/express-delivery/delivery/waiting-on-door' })
  }

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer"></div>;
    }
    return (
      <div className="timer">
        {/* <div className="text">Remaining</div> */}
        <div className="value">{remainingTime}</div>
        {/* <div className="text">seconds</div> */}
      </div>
    );
  }

  useEffect(() => {
    calculateRoute()
    dispatch(getDeliveryDetailsAsync())
  }, []);

  return (
    <>
      <div className="dlvpickup-main">
        <div className="MapOrders">
          <div className="MapOrders_inner">
            <GoogleMap
              center={center}
              zoom={15}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={{
                zoomControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
              }}
              onLoad={map => setMap(map)}
            >
              {directionsResponse && (
                <DirectionsRenderer directions={directionsResponse} />
              )}
            </GoogleMap>
          </div>
          {" "}
        </div>

        <div className="reqaccpt-exprs text-center">
          <span>
            Expree Delivery request accepted: <strong>1</strong>
          </span>
        </div>

        <div className="track-lcitem track-lctdl3">

        </div>

        <div className="pickup-pointbox">
          <div className="accept-mdlupper" onClick={() => handleClick(true)}>
            <h2>
              Way to<span>Delivery Point</span>
            </h2>
            <img className="dropArrow" src={downArrow} onClick="" />
            <div className="timer-wrapper">
              <div className="timer">
                <CountdownCircleTimer
                  isPlaying
                  duration={30}
                  // colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  // colorsTime={[10, 6, 3, 0]}
                  colors={"#1FB3FF"}
                  onComplete={() => ({ shouldRepeat: true, delay: 1 })}
                  size={58}
                  strokeWidth={8}
                >
                  {renderTime}
                </CountdownCircleTimer>
              </div>
            </div>
          </div>
          <div className={middlebody ? 'statusBody addClass' : "statusBody"}>



            <div className="accptmdl-uprtimeline">
              <ul>
                <li>
                  <div className="tmmin-cnt">
                    <p>
                      {distance} {" "}
                      <span>{duration} </span>
                    </p>
                  </div>
                  <div className="logot-icon2">
                    <img src={LogoIcon} alt="" />
                  </div>
                </li>
                <li>
                  <div className="prof-tml2">
                    <img src={Timeline} alt="" />
                  </div>
                </li>
              </ul>
            </div>

            <div className="accept-mdltimline">
              <div className="order-prt">
                <img src={Order} alt="" />
                <div className="order-cnt">
                  <h2>{deliveryData?.recipientName}</h2>
                  <p>{locationData?.order_delivery_address}</p>
                  <h2>Order#</h2>
                  <p>{deliveryData?.orderNo}</p>
                  <ul>
                    <li>
                      <Link to="#">
                        <img src={Tell} alt="" /> Call
                      </Link>
                    </li>
                    <li>
                      <Link to="/dashboard/delivery/message">
                        <img src={Chat} alt="" /> Message
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="pickup-mbtn" onClick={handleCompletePcikup}>
              <Link to="#"> Complete Pickup</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


export default WayToDelivery;

