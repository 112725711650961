import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../public/images/logo.svg";
import LockDark from "../../public/images/lock-dark.png";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2'
import { useDispatch } from "react-redux";
import { registerOtpSendAsync } from "./authSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const ForgotPin = (props) => {
  const history = useHistory();

  const [phoneNo, setPhoneNo] = useState("");
  const [phoneCode, SetPhoneCode] = useState("");
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const onChangePhoneNumber = (value, data, event, formattedValue) => {
    let phoneCode = data.dialCode;
    let phoneNumber = value.slice(data.dialCode.length);
    setPhoneNo(phoneNumber);
    SetPhoneCode(phoneCode);
  }
  let value = {
    "phone_code": `+${phoneCode}`,
    "phone_no": phoneNo,
    "isAlreadyCheck": true
  }

  const sendOtp = () => {
    if (!phoneNo) {
      toast.error("Please enter phone number");
      return;
    }
    else if (phoneNo && phoneNo.length <= 4) {
      toast.error("Phone number length should be of 5 to 15 digits");
      return;
    }
    else if (phoneNo && !phoneCode) {
      toast.error("Please select your phone code");
      return;
    }
    dispatch(registerOtpSendAsync(value))
    .then(unwrapResult)
      .then((obj) => {
        console.log(obj,"object data")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push('/verify-phone');
        localStorage.setItem("phonedetails", JSON.stringify(value))
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
  }

  useEffect(() => { }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="logo-part">
          <Link to="#">
            <img src={Logo} alt="" />
          </Link>
        </div>

        <div className="lock-part">
          <img src={LockDark} alt="" />
        </div>

        <div className="phone-numbpart">
          <h2>Enter your Phone number</h2>
          <p>We’ll send you OTP to set your new PIN</p>
          <div className="country-plugin">
            <form action="#" name="POST">
              <div id="result">
                <PhoneInput
                  country="us"
                  // value={phoneNo}
                  enableSearch={true}
                  placeholder="Phone no."
                  onChange={(value, data, event, formattedValue) => { onChangePhoneNumber(value, data, event, formattedValue) }}
                />
                <span id="valid-msg" className="hide">✓ Valid</span>
                <span id="error-msg" className="hide"></span>
              </div>
            </form>
            <div className="bottom-part">
              <Link to="#" className="bottom-btn" onClick={() => { sendOtp() }}>Send</Link>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ForgotPin;
