// import axios from "axios";
import RestClient from '../../../utilities/RestClient'
import axios from 'axios';
import { toast } from "react-toastify";

export function registerApi(data, token) {
  console.log("token in register api", token)
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        localStorage.removeItem("invaliduserdata")
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invaliduserdata", error?.response?.data?.msg)
        toast.error(error?.response?.data?.msg)
        // console.error(error?.response, "response msg");
      })
  )
}
export function registerSendOtp(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        // console.error(error);
      })
  )
}
export function verifyOtp(data) {
  console.log(data, "verify api data")
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones/verify-phone/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function loginApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/login/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function emailSendApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/send-otp`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        localStorage.removeItem("invaliddata")
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invaliddata", error?.response?.data?.msg)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function emailVerifiedApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/verify-otp/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        localStorage.removeItem("invalidotp")
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invalidotp", error?.response?.data?.msg)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function getCountryApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/countries`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getDistrictApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/districts?country_id=${data}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getStateApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/districts?country_id=${data}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getCityApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_locations/cities?state_id=${data}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getVehicleMakeApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/vehicle_makes`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getVehicleModelApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/vehicle_models`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getVehicleVinsApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/vehicle_vins`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getVehicleYearsApi() {
  return new Promise((resolve, reject) =>
    axios
      .get(`${process.env.REACT_APP_USER_SERVICES}api/v1/vehicle_years`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        console.error(error);
      })
  )
}
export function getUploadDocumentUrlApi(data) {
  var formData = new FormData();
  formData.append('document', data.document);
  console.log(data, "datfgdfga")
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/verification-document`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
export function driverDocumentStepApi(data, token) {
  console.log("token in api", token)
  return new Promise((resolve, reject) =>
    axios
      .put(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_profiles/vehical-document`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
      .then(function (response) {
        localStorage.removeItem("invaliddata")
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invaliddata", error?.response?.data?.msg)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function changePinApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/users/change-pin/`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        localStorage.removeItem("/invalidotp")
        // toast.success(response?.data?.msg)
        localStorage.removeItem("setpin")
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        localStorage.setItem("invalidotp", error?.response?.data?.msg)
        localStorage.setItem("setpin", error?.response?.data?.msg)
        console.error(error);
      })
  )
}

export function roleApi(data) {
  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_roles`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(function (response) {
        // toast.success(response?.data?.msg)
        resolve(response);
      })
      .catch(function (error) {
        reject(error)
        toast.error(error?.response?.data?.msg)
        console.error(error);
      })
  )
}
  // RestClient.post(`${process.env.REACT_APP_USER_SERVICES}api/v1/user_phones/verify-phone/`, data)
  // .then(result => {
  //   let response = result.data;
  //   return response
  // })
  // .catch(function (error) {
  //   console.error(error);
  // });