import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectLoginAuth } from '../containers/auth/authSlice';

const Authenticator = (props) => {
  const history = useHistory();
  const auth = useSelector(selectLoginAuth)
  const authenticateUser = (isAuthenticationRequired) => {
    if (auth?.payload?.token) {
      // If user are logedin then we can perform any action here

    }
    else if (isAuthenticationRequired) {
      // If user are not login then redirect him to the login page
      history.push('/login');
    }
  }

  useEffect(() => {
    authenticateUser(props.isAuthenticationRequired);
  }, []);

  return (
    <>
      {props.children}
    </>
  )

}

export default Authenticator;