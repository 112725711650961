import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from "./routes";
import { store, persistor } from "./app/store";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import Online1 from '../src/app/containers/dashboard/offline';
import Offline1 from '../src/app/containers/dashboard/online';
import { Offline, Online } from "react-detect-offline";
// import './app/public/css/custom.css';
// import './app/public/css/responsive.css';
// import './app/public/css/user-auth.css';
// import './app/public/fonts/stylesheet.css';


const App = () => {

  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        {/* <Offline>
          <Offline1 />
        </Offline>
        <Online>
          <Online1  />
        </Online> */}
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
          </PersistGate>
      </Provider>

      <ToastContainer 
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </React.StrictMode>
  );
}

export default App;
