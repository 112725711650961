import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    getDeliveryDetails,
    getVerifyPickupData,
    getReturnData
} from "./deliveryApi";

const initialState = {
    delivery: null,
    pickup: null,
    returnData: null,
    status: {
        delivery: 'idle',
        pickup: 'idle',
        returnData: 'idle'
    },
};

export const getDeliveryDetailsAsync = createAsyncThunk("delivery/waytodelivery", async (data) => {
    const response = await getDeliveryDetails();
    return response;
});
export const getPickupDetailsAsync = createAsyncThunk("delivery/pickup", async (data) => {
    const response = await getVerifyPickupData();
    return response;
});
export const getReturnDetailsAsync = createAsyncThunk("delivery/return", async (data) => {
    const response = await getReturnData();
    return response;
});

export const deliverySlice = createSlice({
    name: "delivery",
    initialState,

    extraReducers:{
        [getDeliveryDetailsAsync.pending](state){
            state.status.delivery = "loading";
        },
        [getDeliveryDetailsAsync.fulfilled](state, { payload }) {
            state.status.delivery = "idle";
            state.delivery = payload;
        },
        [getDeliveryDetailsAsync.rejected](state, { error }) {
            state.status.delivery = "idle";
            state.delivery = [];
          },
        [getPickupDetailsAsync.pending](state){
            state.status.pickup = "loading";
        },
        [getPickupDetailsAsync.fulfilled](state, { payload }) {
            state.status.pickup = "idle";
            state.pickup = payload;
        },
        [getPickupDetailsAsync.rejected](state, { error }) {
            state.status.pickup = "idle";
            state.pickup = [];
          },
        [getReturnDetailsAsync.pending](state){
            state.status.returnData = "loading";
        },
        [getReturnDetailsAsync.fulfilled](state, { payload }) {
            state.status.returnData = "idle";
            state.returnData = payload;
        },
        [getReturnDetailsAsync.rejected](state, { error }) {
            state.status.returnData = "idle";
            state.returnData = [];
          }
    },
});

export const selectDeliveryData = (state) => state.delivery.delivery;
export const selectPickupData = (state) => state.delivery.pickup;
export const selectReturnData = (state) => state.delivery.returnData;

export default deliverySlice.reducer;