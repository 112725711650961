import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../public/images/left-arrow.svg";
import { useSelector, useDispatch } from "react-redux";
import { getTrackDataAsync, getTripDataApiAsync, selectTrackData, selectTripData } from "./dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";
import moment from "moment-timezone";

const AppTrackerList = (props) => {
  const history = useHistory();
  const [filterData, setFilterData] = useState("day")
  const appTrackerData = useSelector(selectTrackData)
  const auth = useSelector(selectLoginAuth)
  const tripData = useSelector(selectTripData)
  const dispatch = useDispatch()

  // console.log(tripData?.data?.payload?.data, "trip data")
  const handleChange = async(e) => {
    setFilterData(e.target.value)
    let data = {
      "postData": e.target.value,
      "tokenData": auth?.payload?.token
    }
    await dispatch(getTripDataApiAsync(data))
  }
  let data = {
    "postData": "",
    "tokenData": auth?.payload?.token
  }
  useEffect(() => {
    dispatch(getTrackDataAsync())
    dispatch(getTripDataApiAsync(data))
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
              history.push("/dashboard/app-tracker");
            }}
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          Total trips
        </h4>
        <div className="fuel-btn">
          <Link
            to=""
            onClick={(e) => {
              e.preventDefault();
              history.push("/dashboard/app-tracker-list-2");
            }}
          >
            Add fuel
          </Link>
        </div>
      </div>

      <div className="tracklist-main">
        <div className="tracklist-search">
          <form action="#" method="POST">
            <input type="search" placeholder="Search here" />
          </form>
        </div>

        <div className="trip-upper">
          <h4>Trips</h4>
          <form action="#" method="POST">
            <select name="" id="" onChange={(e)=>handleChange(e)}>
              <option value="day">Daily</option>
              <option value="week">Weekly</option>
              <option value="month">Monthly</option>
              <option value="year">Yearly</option>
            </select>
          </form>
        </div>

        <div className="apptrack-table">
          <table>
            <thead>
              <tr>
                <th>Trip</th>
                <th>Distance</th>
                <th>Fuel</th>
                <th>Cost</th>
                <th>Earned</th>
              </tr>
            </thead>
            <tbody>
              {appTrackerData?.tripData?.tripList.length ? (
                <>
                  {tripData?.data?.payload?.data.map((value, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td>{moment(value?.created_at).format('MMM DD, YYYY')}</td>
                          <td>{value.distance} km</td>
                          <td>{value.fuel_usage} gal</td>
                          <td>${value.cost}</td>
                          <td>{value.amount}</td>
                        </tr>
                      </React.Fragment>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AppTrackerList;