import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import MessageGreen from "../../../public/images/sent-message-green.png";
import Logo from "../../../public/images/logo.svg";
// import PinField from "react-pin-field"
import OTPInput from 'react-otp-input';
import { useDispatch, useSelector } from "react-redux";
import { emailSendAsync, emailVerifiedAsync, selectEmailOtpId } from "../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const VerifyEmailOtp = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const toastId = React.useRef(null)
  const [pin, setPin] = useState("");
  // const emailVerified = useSelector(selectEmailVerified)
  const emailOtp = useSelector(selectEmailOtpId)
  const [loading, setLoading] = useState(false);
  const { status1 } = useSelector(state => state.auth)

  const handleChange = (pin) => {
    setPin(pin);
  }
 console.log(emailOtp,"emailOtp")
  const handleOnSubmit = async () => {
    let data = {
      "type": "email",
      id: emailOtp?.payload?.id,
      otp: Number(pin),
      "role_id": 3
    }
    if (!pin || pin.length < 5) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Please enter valid PIN");
      }
      return false
    }
    await dispatch(emailVerifiedAsync(data, history))
    .then(unwrapResult)
      .then((obj) => {
        console.log(obj,"registeremailverify")
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success(obj?.msg)
        }
        history.push('/area-code-with-state')
      }
      )
      .catch((obj) => {
        // if (!toast.isActive(toastId.current)) {
        //   toastId.current = toast.error(obj?.message)
        // }
      })
 
  }
  let emailData = localStorage.getItem("email")
  const resendEmail = () => {
    const data = {
      "type": "email",
      "email": emailData
    }
    dispatch(emailSendAsync(data))
    .then(unwrapResult)
    .then((obj) => {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(obj?.msg)
      }
    }
    )
    .catch((obj) => {
    })
  }

  // console.log(props, "propss dsa")

  useEffect(() => {
    // props.logout();
  }, []);

  return (
    <>
      <main className="main-wrapper">
        <div className="logo-part">
          <Link to="#">
            <img src={Logo} alt="" />
          </Link>
        </div>

        <div className="lock-part sent-message">
          <img src={MessageGreen} alt="" />
        </div>

        <div className="regmain-part">
          <h2>
            Verify your <strong>email address</strong>
          </h2>
          <p>We’ve sent code to your email address.</p>
          <div className="pin-box d-flex justify-content-center" >
            <OTPInput numInputs={5}
              className='input_digits_'
              value={pin}
              isInputNum={true}
              data-cy="pin-field"
              onChange={handleChange}
            // onComplete={(code) => onComplete(code)}
            />
          </div>
        </div>

        <div className="bottom-part">
          <p>
            Didn’t receive Code? <Link to="#" onClick={() => resendEmail()}>Resend</Link>
          </p>
          <button className="btn btn-primary continue" disabled={status1?.VerifyEmailStatus === "loading"} onClick={handleOnSubmit}>
            {status1?.VerifyEmailStatus === "loading" && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            &nbsp;&nbsp;
            Continue</button>
        </div>
      </main>
    </>
  );
};

export default VerifyEmailOtp;