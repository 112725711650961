import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { toast } from "react-toastify";
import LeftArrow from "../../public/images/left-arrow.svg";
import LogoIcon from "../../public/images/logo_icon.svg";
import "react-phone-number-input/style.css";
import { useSelector, useDispatch } from "react-redux";
import { getRewardAsync, getRideHistoryApiAsync, selectMyRewardsData, selectRideHistoryData } from "../../containers/dashboard/dashbaordSlice"
import { selectLoginAuth } from "../auth/authSlice";
import moment from "moment-timezone";

const Reward = (props) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const reward = useSelector(selectMyRewardsData);
  const auth = useSelector(selectLoginAuth)
  const rideData = useSelector(selectRideHistoryData)

  useEffect(() => {
    dispatch(getRewardAsync())
    dispatch(getRideHistoryApiAsync(auth?.payload?.token))
  }, []);

  return (
    <>
      <div className="registration-upper reg-heading2">
        <h4>
          <Link
            to="/express-pickup-request"
          >
            <img src={LeftArrow} alt="" />
          </Link>{" "}
          Rewards
        </h4>
      </div>

      <div className="reward-main">
        {reward?.rewardData.category === "bronze" && (
          <>
            <div className={(auth?.payload?.user_profiles?.reward_status_id === null || auth?.payload?.user_profiles?.reward_status_id === 1) ?
              "reward-box" : (auth?.payload?.user_profiles?.reward_status_id === 2) ? "reward-box reward-silver" : (auth?.payload?.user_profiles?.reward_status_id === 3) ? "reward-box reward-gold" : (auth?.payload?.user_profiles?.reward_status_id === 4) ? "reward-box reward-silver reward-platinum" : ""}>
              <h4>
                {auth?.payload?.user_profiles?.firstname}{" "}{auth?.payload?.user_profiles?.lastname}
                <span>
                  <i className="fas fa-trophy"></i>{" "}
                  {auth?.payload?.user_profiles?.reward_point}
                </span>
              </h4>
              <h3>
                <strong>Bronze</strong>{" "}
                <span>{reward?.rewardData.maxRewardPoints}</span>
              </h3>
              <div className="progress">
                <div className="progress-bar"></div>
              </div>
              <p>Points will be used for redeem gifts*</p>
            </div>
          </>
        )}

        {/* <div className="reward-collectbox">
        <h2>Rewards</h2>
        <div className="collect-item">
          <div className="collect-inner">
            <img src={LogoIcon} alt="" />
            <div className="collect-cnt">
              <h4>JBR {reward?.rewardData.amount}</h4>
              <p>{reward?.rewardData.totalPoints} points</p>
            </div>
          </div>
          <Link to="#">Collect</Link>
        </div>
      </div> */}

        <div className="point-history">
          <h4>Point history</h4>
          <ul>
            {rideData?.data?.payload?.data?.length ? (
              <>
                {rideData?.data?.payload?.data.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li>
                        <div className="history-left">
                          <h4>Delivery Completed</h4>
                          <span>{moment(value?.created_at).format('MMM DD, YYYY')}</span>
                        </div>
                        <div className="history-right">
                          <span>{value.points_scored} Points</span>
                        </div>
                      </li>
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <>No History Found</>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Reward;